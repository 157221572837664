import * as React from "react";

function SvgRestaurantActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#restaurant-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M13.324 7.5c0-.547.292-1.066.875-1.559a3.083 3.083 0 012.051-.765v11.648H14.8v-4.648h-1.476V7.5zm-2.898 1.75V5.176h1.148V9.25c0 .62-.21 1.158-.629 1.613-.419.438-.938.675-1.558.711v5.25h-1.45v-5.25a2.267 2.267 0 01-1.558-.71A2.304 2.304 0 015.75 9.25V5.176h1.176V9.25h1.148V5.176H9.25V9.25h1.176z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="restaurant-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgRestaurantActive;

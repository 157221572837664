import * as React from "react";

function SvgTaxiDefault(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#taxi-default_svg__clip0)">
        <g filter="url(#taxi-default_svg__filter0_dd)">
          <path d="M13 22a9 9 0 100-18 9 9 0 000 18z" fill={props.markerColor} />
        </g>
        <path
          d="M9.217 11.967h7.566l-.812-2.438h-5.942l-.812 2.438zm6.17 2.463c.169.152.364.228.584.228a.76.76 0 00.558-.228.803.803 0 00.254-.584.723.723 0 00-.254-.559.723.723 0 00-.558-.254.803.803 0 00-.584.254.76.76 0 00-.229.559c0 .22.076.414.229.584zm-5.942 0c.17.152.364.228.584.228a.76.76 0 00.559-.228.803.803 0 00.254-.584.723.723 0 00-.254-.559.723.723 0 00-.559-.254.803.803 0 00-.584.254.76.76 0 00-.228.559c0 .22.076.414.228.584zm7.313-5.18l1.117 3.25v4.342a.517.517 0 01-.533.533h-.559a.517.517 0 01-.533-.533v-.559h-6.5v.559a.517.517 0 01-.533.533h-.559a.517.517 0 01-.533-.533V12.5l1.117-3.25c.102-.355.364-.533.787-.533h1.346V7.625h3.25v1.092h1.346c.423 0 .685.178.787.533z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="taxi-default_svg__clip0">
          <path fill={props.markerColor} d="M0 0h26v26H0z" />
        </clipPath>
        <filter
          id="taxi-default_svg__filter0_dd"
          x={0}
          y={2}
          width={26}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgTaxiDefault;

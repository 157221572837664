import * as React from "react";

function SvgTaxiHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M9.217 10.967h7.566l-.812-2.438h-5.942l-.812 2.438zm6.17 2.463c.169.152.364.228.584.228a.76.76 0 00.558-.228.803.803 0 00.254-.584.723.723 0 00-.254-.559.723.723 0 00-.558-.254.803.803 0 00-.584.254.76.76 0 00-.229.559c0 .22.076.414.229.584zm-5.942 0c.17.152.364.228.584.228a.76.76 0 00.559-.228.803.803 0 00.254-.584.723.723 0 00-.254-.559.723.723 0 00-.559-.254.803.803 0 00-.584.254.76.76 0 00-.228.559c0 .22.076.414.228.584zm7.313-5.18l1.117 3.25v4.342a.517.517 0 01-.533.533h-.559a.517.517 0 01-.533-.533v-.559h-6.5v.559a.517.517 0 01-.533.533h-.559a.517.517 0 01-.533-.533V11.5l1.117-3.25c.102-.355.364-.533.787-.533h1.346V6.625h3.25v1.092h1.346c.423 0 .685.178.787.533z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgTaxiHover;

import React from 'react'
import classNames from 'classnames'
import {FormattedMessage} from "react-intl";
import TranslatedValue from "./translated_value";

function NavigationBarItem({ name, active, icon, onClick }) {
  return (
    <li
      className='navigation-bar__item'
      onClick={onClick}
    >
      <div
        className={classNames(
          'navigation-bar__name',
          `navigation-bar__name--${icon}`,
          {
            'navigation-bar__name--active': active,
          },
        )}
      >
        <TranslatedValue values={name}/>
      </div>
    </li>
  )
}

function NavigationBar({ items, activeItem, setActiveItem }) {
  return (
    <ul className='navigation-bar'>
      <li className='navigation-bar__label'><FormattedMessage id="TYPE_OF_TRAVEL"/>:</li>

      {items.map(item => {
        return (
          <NavigationBarItem
            key={item.name.de}
            active={activeItem === item.type}
            name={item.name}
            icon={item.icon}
            onClick={() => setActiveItem(item.type)}
          />
        )
      })}
    </ul>
  )
}

export default NavigationBar

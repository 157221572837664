import * as React from "react";

function SvgPoiDefault(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#poi-default_svg__clip0)">
        <g filter="url(#poi-default_svg__filter0_dd)">
          <path d="M13 22a9 9 0 100-18 9 9 0 000 18z" fill={props.markerColor} />
        </g>
        <path
          d="M11.219 14.781c.5.485 1.094.727 1.781.727.688 0 1.273-.242 1.758-.727.5-.5.75-1.094.75-1.781 0-.688-.25-1.273-.75-1.758-.485-.5-1.07-.75-1.758-.75s-1.281.25-1.781.75A2.394 2.394 0 0010.492 13c0 .688.242 1.281.727 1.781zm.281-6.773h3l.914.984h1.594c.265 0 .492.102.68.305a.966.966 0 01.304.703v6c0 .266-.101.5-.305.703a.892.892 0 01-.68.305H8.993a.966.966 0 01-.703-.305A1.006 1.006 0 018.008 16v-6c0-.266.094-.5.281-.703a.966.966 0 01.703-.305h1.594l.914-.984zm.375 6.117A1.534 1.534 0 0111.406 13c0-.438.156-.813.469-1.125A1.534 1.534 0 0113 11.406c.438 0 .813.156 1.125.469.313.313.469.688.469 1.125 0 .438-.156.813-.469 1.125a1.534 1.534 0 01-1.125.469c-.438 0-.813-.156-1.125-.469z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="poi-default_svg__clip0">
          <path fill={props.markerColor} d="M0 0h26v26H0z" />
        </clipPath>
        <filter
          id="poi-default_svg__filter0_dd"
          x={0}
          y={2}
          width={26}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgPoiDefault;

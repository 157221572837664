import * as React from "react";

function SvgTrainHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M14.854 14.488a.791.791 0 00.584.229.791.791 0 00.583-.229.848.848 0 00.229-.584.76.76 0 00-.229-.558.75.75 0 00-.584-.254.75.75 0 00-.583.254.76.76 0 00-.229.558c0 .22.076.415.229.584zm-1.32-3.58h2.716V8.75h-2.717v2.158zm-1.067 0V8.75H9.75v2.158h2.717zm-2.488 3.58a.791.791 0 00.584.229.791.791 0 00.583-.229.848.848 0 00.229-.584.76.76 0 00-.229-.558.75.75 0 00-.584-.254.75.75 0 00-.583.254.76.76 0 00-.229.558c0 .22.076.415.229.584zM13 6.592c1.405 0 2.48.144 3.225.431.744.288 1.117.864 1.117 1.727v5.154c0 .525-.186.974-.559 1.346a1.879 1.879 0 01-1.346.533l.813.813v.279h-1.092l-1.066-1.092h-2.057l-1.066 1.092H9.75v-.28l.813-.812c-.525 0-.974-.178-1.346-.533a1.834 1.834 0 01-.559-1.346V8.75c0-.457.119-.838.356-1.143.237-.304.575-.524 1.015-.66a7.938 7.938 0 011.346-.279c.457-.05.999-.076 1.625-.076z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgTrainHover;

import React, {useEffect, useRef, useState} from 'react'
import {getActiveLocationTypeName, getLocationTypeFromList} from "./utils";
import {renderToString} from "react-dom/server";
import AirportDefault from "../markers/AirportDefault";
import AirportActive from "../markers/AirportActive";
import AirportHover from "../markers/AirportHover";
import TrainHover from "../markers/TrainHover";
import TrainDefault from "../markers/TrainDefault";
import TrainActive from "../markers/TrainActive";
import TaxiHover from "../markers/TaxiHover";
import TaxiDefault from "../markers/TaxiDefault";
import TaxiActive from "../markers/TaxiActive";
import ShuttleHover from "../markers/ShuttleHover";
import ShuttleDefault from "../markers/ShuttleDefault";
import ShuttleActive from "../markers/ShuttleActive";
import ShoppingHover from "../markers/ShoppingHover";
import ShoppingDefault from "../markers/ShoppingDefault";
import ShoppingActive from "../markers/ShoppingActive";
import SeestadtHover from "../markers/SeestadtHover";
import SeestadtActive from "../markers/SeestadtActive";
import SeestadtDefault from "../markers/SeestadtDefault";
import RestaurantHover from "../markers/RestaurantHover";
import RestaurantActive from "../markers/RestaurantActive";
import RestaurantDefault from "../markers/RestaurantDefault";
import PoiHover from "../markers/PoiHover";
import PoiDefault from "../markers/PoiDefault";
import PoiActive from "../markers/PoiActive";
import PedalbikeHover from "../markers/PedalbikeHover";
import PedalbikeDefault from "../markers/PedalbikeDefault";
import PedalbikeActive from "../markers/PedalbikeActive";
import ParkingHover from "../markers/ParkingHover";
import ParkingDefault from "../markers/ParkingDefault";
import ParkingActive from "../markers/ParkingActive";
import ParkHover from "../markers/ParkHover";
import ParkDefault from "../markers/ParkDefault";
import ParkActive from "../markers/ParkActive";
import FastfoodHover from "../markers/FastfoodHover";
import FastfoodDefault from "../markers/FastfoodDefault";
import FastfoodActive from "../markers/FastfoodActive";
import EntertainmentHover from "../markers/EntertainmentHover";
import EntertainmentDefault from "../markers/EntertainmentDefault";
import EntertainmentActive from "../markers/EntertainmentActive";
import CafeHover from "../markers/CafeHover";
import CafeDefault from "../markers/CafeDefault";
import CafeActive from "../markers/CafeActive";
import BusHover from "../markers/BusHover";
import BusDefault from "../markers/BusDefault";
import BusActive from "../markers/BusActive";
import BarHover from "../markers/BarHover";
import BarDefault from "../markers/BarDefault";
import BarActive from "../markers/BarActive";

const SCALE_FACTORS = {
    active: 1.3,
    hover: 1.45,
    default: 0.95
}

const DEFAULT_ICON_HEIGHT_WIDTH = 25;

const MARKERS = {
    "airport-active": AirportActive,
    "airport-default": AirportDefault,
    "airport-hover": AirportHover,
    "bar-active": BarActive,
    "bar-default": BarDefault,
    "bar-hover": BarHover,
    "bus-active": BusActive,
    "bus-default": BusDefault,
    "bus-hover": BusHover,
    "cafe-active": CafeActive,
    "cafe-default": CafeDefault,
    "cafe-hover": CafeHover,
    "entertainment-active": EntertainmentActive,
    "entertainment-default": EntertainmentDefault,
    "entertainment-hover": EntertainmentHover,
    "fastfood-active": FastfoodActive,
    "fastfood-default": FastfoodDefault,
    "fastfood-hover": FastfoodHover,
    "park-active": ParkActive,
    "park-default": ParkDefault,
    "park-hover": ParkHover,
    "parking-active": ParkingActive,
    "parking-default": ParkingDefault,
    "parking-hover": ParkingHover,
    "pedalbike-active": PedalbikeActive,
    "pedalbike-default": PedalbikeDefault,
    "pedalbike-hover": PedalbikeHover,
    "poi-active": PoiActive,
    "poi-default": PoiDefault,
    "poi-hover": PoiHover,
    "restaurant-active": RestaurantActive,
    "restaurant-default": RestaurantDefault,
    "restaurant-hover": RestaurantHover,
    "seestadt-active": SeestadtActive,
    "seestadt-default": SeestadtDefault,
    "seestadt-hover": SeestadtHover,
    "shopping-active": ShoppingActive,
    "shopping-default": ShoppingDefault,
    "shopping-hover": ShoppingHover,
    "shuttle-active": ShuttleActive,
    "shuttle-default": ShuttleDefault,
    "shuttle-hover": ShuttleHover,
    "taxi-active": TaxiActive,
    "taxi-default": TaxiDefault,
    "taxi-hover": TaxiHover,
    "train-active": TrainActive,
    "train-default": TrainDefault,
    "train-hover": TrainHover,
}

function GoogleMap({
                       config,
                       locationSelected,
                       selectedLocation,
                       hoveredLocation,
                       locations,
                       showTransitLines,
                       fullscreen,
                       onMobile,
                       onLargeScreen, activeLocationTypeFilters, activeRunningRoute, runningRoutes
                   }) {
    const [currentHoveredLocation, setCurrentHoveredLocation] = useState()
    const [currentSelectedLocation, setCurrentSelectedLocation] = useState()
    const mapRef = useRef()
    const transitLayerRef = useRef()
    const activeRunningRouteRef = useRef();
    const googleMap = useRef()
    const hotelMarker = useRef()
    const markers = useRef(new Map())
    const runningRoutesRef = useRef([]);
    const iconBase = '/svg_icons'

    useEffect(() => {
        createMap(config)
        createHotelMarker(config)
    }, [])

    useEffect(() => {
        createMarkers()
    }, [locations])

    useEffect(() => {
        if (selectedLocation) {
            setTimeout(() => showNotVisibleLocation(selectedLocation), 300)
        }
    }, [selectedLocation])

    useEffect(() => {
        if (
            currentHoveredLocation &&
            currentSelectedLocation !== currentHoveredLocation
        ) {
            changeMarker(currentHoveredLocation)
        }
        if (hoveredLocation) {
            changeMarker(hoveredLocation, 'hover')
        }
        setCurrentHoveredLocation(hoveredLocation)
    }, [hoveredLocation])

    useEffect(() => {
        if (
            currentSelectedLocation &&
            currentSelectedLocation !== currentHoveredLocation
        ) {
            changeMarker(currentSelectedLocation)
        }
        if (selectedLocation) {
            changeMarker(selectedLocation, 'active')
        }
        setCurrentSelectedLocation(selectedLocation)
    }, [selectedLocation])

    useEffect(() => {
        if (showTransitLines) {
            transitLayerRef.current = new window.google.maps.TransitLayer()
            transitLayerRef.current.setMap(googleMap.current)
            googleMap.current.setOptions({styles: config.mapConfigWithTransit})
        } else if (transitLayerRef.current) {
            transitLayerRef.current.setMap(null)
            transitLayerRef.current = null
            googleMap.current.setOptions({styles: config.mapConfig})
        }
    }, [showTransitLines])

    useEffect(() => {
        if (activeRunningRouteRef.current) {
            activeRunningRouteRef.current.setMap(null)
            activeRunningRouteRef.current = null;
        }
        if (activeRunningRoute) {
            activeRunningRouteRef.current = new window.google.maps.Polyline({
                path: activeRunningRoute.map(c => ({lat: c[1], lng: c[0]})),
                geodesic: true,
                strokeColor: config.theme.runningRouteColor,
                strokeOpacity: 1.0,
                strokeWeight: 4,
            });
            activeRunningRouteRef.current.setMap(googleMap.current)
        }
    }, [activeRunningRoute]);

    useEffect(() => {
        runningRoutesRef.current.forEach(r => r.setMap(null))
        runningRoutesRef.current = []

        runningRoutes.forEach(drawInactiveRoute);

    }, [runningRoutes])


    useEffect(updateMapOptions, [onMobile, fullscreen])

    function updateMapOptions() {
        if (googleMap.current) {
            googleMap.current.setOptions({
                gestureHandling: (fullscreen ? 'greedy' : 'auto'),
                scrollwheel: onMobile || fullscreen,
                zoomControl: !onMobile
            })
        }
    }

    function drawInactiveRoute(route) {
        const newLine = new window.google.maps.Polyline({
            path: route.route.map(c => ({lat: c[1], lng: c[0]})),
            geodesic: true,
            strokeColor: config.theme.runningRouteColor,
            strokeOpacity: 0,
            strokeWeight: 4,
            icons: [{
                icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: config.theme.runningRouteColor,
                    fillOpacity: 0.5,
                    scale: 2.5
                },
                offset: '0',
                repeat: '7px'
            }],
        });

        newLine.setMap(googleMap.current)
        runningRoutesRef.current.push(newLine)
    }

    function iconSrc(iconName) {
        return `${iconBase}/${iconName}.svg`
    }

    function createMap() {
        googleMap.current = new window.google.maps.Map(mapRef.current, {
            zoom: 14,
            styles: config.mapConfig,
            center: {
                lat: config.hotel.location.lat,
                lng: config.hotel.location.lng,
            },
            disableDefaultUI: true,
            scrollwheel: onMobile,
            zoomControl: !onMobile
        })
    }

    function createHotelMarker() {
        hotelMarker.current = new window.google.maps.Marker({
            position: {
                lat: config.hotel.location.lat,
                lng: config.hotel.location.lng,
            },
            map: googleMap.current,
            icon: iconSrc(config.markers.hotels),
            zIndex: window.google.maps.Marker.MAX_ZINDEX + 3,
            clickable: false,
        })
    }

    function icon(marker, highlight, colorSet) {
        function scale(original) {
            return Math.round(SCALE_FACTORS[highlight] * original);
        }

        highlight = highlight || 'default'
        const Marker = MARKERS[`${marker}-${highlight}`]
        colorSet = colorSet || config.theme.markerColors;

        return {
            url: 'data:image/svg+xml;utf-8,' + encodeURIComponent(renderToString(<Marker
                iconColor={colorSet[highlight + "IconColor"]} markerColor={colorSet[highlight + "MarkerColor"]}
                outlineColor={colorSet.hoverOutlineMarkerColor || config.theme.markerColors.hoverOutlineMarkerColor}/>)),
            anchor: markerCenter(highlight),
            origin: point(0,0),
            scaledSize: new window.google.maps.Size(scale(DEFAULT_ICON_HEIGHT_WIDTH ), scale(DEFAULT_ICON_HEIGHT_WIDTH))
        }
    }

    function zIndex(highlight) {
        switch (highlight) {
            case 'hover':
                return window.google.maps.Marker.MAX_ZINDEX + 1
            case 'active':
                return window.google.maps.Marker.MAX_ZINDEX + 2
            default:
                return window.google.maps.Marker.MAX_ZINDEX
        }
    }

    function point(horizontalDistanceFromTopLeft, verticalDistanceFromTopLeft) {
        return new window.google.maps.Point(
            horizontalDistanceFromTopLeft,
            verticalDistanceFromTopLeft,
        )
    }

    function markerCenter(highlight) {
        switch (highlight) {
            case 'hover':
                return point(21,  30)
            case 'active':
                return point(20, 31)
            default:
                return point(15, 12)
        }
    }

    function changeMarker(location, highlight) {
        const marker = markers.current.get(location)
        if (marker) {
            const locationType = getLocationType(location);
            marker.setIcon(icon(locationType.marker, highlight, locationType.markerColors))
            marker.setZIndex(zIndex(highlight))
        }
    }

    function bottomPadding() {
        return onMobile ? 160 : 0
    }

    function leftPadding() {
        return onLargeScreen ? 373 + 80 : 80
    }

    function rightPadding() {
        return 80
    }

    function topPadding() {
        return 45
    }

    function showNotVisibleLocation(location) {
        const hotelPosition = new window.google.maps.LatLng(
            config.hotel.location.lat,
            config.hotel.location.lng,
        )
        const bounds = new window.google.maps.LatLngBounds()
        const mapBounds = paddedBounds(
            topPadding(),
            bottomPadding(),
            rightPadding(),
            leftPadding(),
        )

        function fitBounds() {
            googleMap.current.fitBounds(bounds, {
                bottom: bottomPadding(),
                top: topPadding(),
                left: leftPadding(),
                right: rightPadding(),
            })
        }

        if (location.route) {
            const routePoints = location.route.map(r => new window.google.maps.LatLng(r[1], r[0]))
            const allRoutePointsVisible = routePoints.every(ll => mapBounds.contains(ll));

            if (!allRoutePointsVisible) {
                bounds.extend(hotelPosition)
                routePoints.forEach((ll) => bounds.extend(ll))
                fitBounds();
            }
        }
        if (location.lat) {
            const locationPosition = new window.google.maps.LatLng(
                location.lat,
                location.lng,
            )

            if (mapBounds && !mapBounds.contains(locationPosition)) {
                bounds.extend(locationPosition)
                bounds.extend(hotelPosition)
                fitBounds()
            }
        }
    }

    function getLocationType(location) {
        return getLocationTypeFromList([...config.transportationTypes, ...config.locationTypes], getActiveLocationTypeName(activeLocationTypeFilters, location.type));
    }

    function drawMarker(location, highlight) {
        if (!markers.current.get(location)) {
            const locationType = getLocationType(location);
            const marker = new window.google.maps.Marker({
                position: {lat: location.lat, lng: location.lng},
                map: googleMap.current,
                icon: icon(locationType.marker, highlight, locationType.markerColors),
                zIndex: zIndex(highlight),
            })
            marker.addListener('click', locationSelected(location))
            markers.current.set(location, marker)
        }
        return markers.current.get(location)
    }

    function createMarkers() {
        clearMarkersWithoutCorrespondingLocation()
        locations.forEach(location => drawMarker(location))
    }

    function clearMarkersWithoutCorrespondingLocation() {
        markers.current.forEach((marker, location) => {
            if (!locations.includes(location)) {
                marker.setMap(null)
                markers.current.delete(location)
            }
        })
    }

    // npad nortPad
    function paddedBounds(npad, spad, epad, wpad) {
        const SW = googleMap.current.getBounds().getSouthWest()
        const NE = googleMap.current.getBounds().getNorthEast()
        const topRight = googleMap.current.getProjection().fromLatLngToPoint(NE)
        const bottomLeft = googleMap.current.getProjection().fromLatLngToPoint(SW)
        const scale = 2 ** googleMap.current.getZoom()

        const SWtopoint = googleMap.current.getProjection().fromLatLngToPoint(SW)
        const SWpoint = new window.google.maps.Point(
            (SWtopoint.x - bottomLeft.x) * scale + wpad,
            (SWtopoint.y - topRight.y) * scale - spad,
        )
        const SWworld = new window.google.maps.Point(
            SWpoint.x / scale + bottomLeft.x,
            SWpoint.y / scale + topRight.y,
        )
        const pt1 = googleMap.current.getProjection().fromPointToLatLng(SWworld)

        const NEtopoint = googleMap.current.getProjection().fromLatLngToPoint(NE)
        const NEpoint = new window.google.maps.Point(
            (NEtopoint.x - bottomLeft.x) * scale - epad,
            (NEtopoint.y - topRight.y) * scale + npad,
        )
        const NEworld = new window.google.maps.Point(
            NEpoint.x / scale + bottomLeft.x,
            NEpoint.y / scale + topRight.y,
        )
        const pt2 = googleMap.current.getProjection().fromPointToLatLng(NEworld)

        return new window.google.maps.LatLngBounds(pt1, pt2)
    }

    return <div className='map__google-map' id='map' ref={mapRef}/>
}

export default GoogleMap

import * as React from "react";

function SvgFastfoodActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#fastfood-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M14.306 16h.905c.458 0 .835-.35.889-.797L17 6.21h-2.727V4h-1.075v2.21h-2.71l.163 1.277c.933.257 1.805.72 2.329 1.233.785.775 1.325 1.577 1.325 2.887V16zM5 15.454v-.54h8.198v.54c0 .3-.245.546-.55.546H5.55A.548.548 0 015 15.454zm8.198-3.82c0-4.365-8.198-4.365-8.198 0h8.198zm-8.187 1.097h8.182v1.092H5.01V12.73z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="fastfood-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgFastfoodActive;

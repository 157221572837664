import * as React from "react";

function SvgShoppingActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#shopping-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M8.922 10.227a2.885 2.885 0 002.078.847c.802 0 1.486-.282 2.05-.847.584-.566.876-1.25.876-2.051H12.75c0 .474-.173.884-.52 1.23-.346.347-.756.52-1.23.52s-.884-.173-1.23-.52a1.682 1.682 0 01-.52-1.23H8.074c0 .802.283 1.485.848 2.05zM12.23 5.77A1.682 1.682 0 0011 5.25c-.474 0-.884.173-1.23.52-.347.346-.52.756-.52 1.23h3.5c0-.474-.173-.884-.52-1.23zM15.074 7c.31 0 .584.118.82.355.237.237.356.51.356.82v7c0 .31-.119.584-.355.821-.237.219-.51.328-.82.328h-8.15c-.31 0-.583-.11-.82-.328a1.127 1.127 0 01-.355-.82v-7c0-.31.118-.584.355-.82.237-.238.51-.356.82-.356h1.15c0-.802.282-1.486.847-2.05.583-.584 1.276-.876 2.078-.876.802 0 1.486.292 2.05.875.584.565.876 1.249.876 2.051h1.148z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="shopping-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgShoppingActive;

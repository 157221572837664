import * as React from "react";

function SvgTrainActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#train-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M12.996 13.68a.852.852 0 00.629.246.852.852 0 00.629-.246.912.912 0 00.246-.63.818.818 0 00-.246-.6.808.808 0 00-.629-.274.808.808 0 00-.629.273.818.818 0 00-.246.602c0 .237.082.446.246.629zm-1.422-3.856H14.5V7.5h-2.926v2.324zm-1.148 0V7.5H7.5v2.324h2.926zm-2.68 3.856a.852.852 0 00.629.246.852.852 0 00.629-.246.913.913 0 00.246-.63.818.818 0 00-.246-.6.808.808 0 00-.629-.274.808.808 0 00-.629.273.818.818 0 00-.246.602c0 .237.082.446.246.629zM11 5.176c1.513 0 2.67.155 3.473.465.802.31 1.203.93 1.203 1.859v5.55c0 .566-.2 1.049-.602 1.45a2.023 2.023 0 01-1.449.574l.875.875v.301h-1.176l-1.148-1.176H9.96L8.812 16.25H7.5v-.3l.875-.876a2.024 2.024 0 01-1.45-.574 1.975 1.975 0 01-.6-1.45V7.5c0-.492.127-.902.382-1.23.255-.329.62-.566 1.094-.711a8.55 8.55 0 011.449-.301A16.113 16.113 0 0111 5.176z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="train-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgTrainActive;

import * as React from "react";

function SvgCafeHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M7.592 17.875v-1.092h9.75v1.092h-9.75zm9.75-7.033V9.217H16.25v1.625h1.092zm0-2.717c.305 0 .558.11.762.33.203.203.304.457.304.762v1.625c0 .304-.101.558-.305.761a1.033 1.033 0 01-.761.305H16.25v1.625c0 .593-.212 1.109-.635 1.549a2.078 2.078 0 01-1.523.635h-3.25a2.162 2.162 0 01-1.549-.635 2.162 2.162 0 01-.635-1.549V8.125h8.684z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgCafeHover;

import * as React from "react";

function SvgBarActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#bar-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M8.348 8.574h5.304l1.04-1.148H7.308l1.039 1.148zm7.902-1.148l-4.676 5.25v2.898H14.5v1.176h-7v-1.176h2.926v-2.898L5.75 7.426V6.25h10.5v1.176z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="bar-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgBarActive;

import * as React from "react";

function SvgEntertainmentActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#entertainment-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M13.078 13.29l-.629-2.38 1.914-1.586-2.46-.136L11 6.89l-.902 2.296-2.489.137 1.942 1.586-.63 2.38L11 11.95l2.078 1.34zm2.926-3.61c-.219.237-.328.51-.328.82 0 .31.11.583.328.82.237.237.51.356.82.356V14c0 .31-.118.583-.355.82a1.04 1.04 0 01-.793.356H6.324c-.31 0-.583-.119-.82-.356a1.173 1.173 0 01-.328-.82v-2.324c.328 0 .601-.11.82-.328.219-.237.328-.52.328-.848 0-.31-.118-.583-.355-.82a1.04 1.04 0 00-.793-.356V7c0-.31.11-.583.328-.82.237-.237.51-.356.82-.356h9.352c.31 0 .574.119.793.356.237.237.355.51.355.82v2.324c-.31 0-.583.119-.82.356z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="entertainment-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgEntertainmentActive;

import * as React from "react";

function SvgBarHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M10.537 10.783h4.926l.965-1.066H9.572l.965 1.066zm7.338-1.066l-4.342 4.875v2.691h2.717v1.092h-6.5v-1.092h2.717v-2.691L8.125 9.717V8.625h9.75v1.092z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgBarHover;

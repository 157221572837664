import * as React from "react";

function SvgTaxiActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#taxi-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M6.926 9.426h8.148L14.2 6.8H7.801l-.875 2.625zm6.644 2.652a.912.912 0 00.63.246.818.818 0 00.6-.246.864.864 0 00.274-.629.779.779 0 00-.273-.601.779.779 0 00-.602-.274.864.864 0 00-.629.274.818.818 0 00-.246.601c0 .237.082.447.246.63zm-6.398 0a.912.912 0 00.629.246.818.818 0 00.601-.246.864.864 0 00.274-.629.779.779 0 00-.274-.601.779.779 0 00-.601-.274.865.865 0 00-.63.274.818.818 0 00-.245.601c0 .237.082.447.246.63zM15.047 6.5L16.25 10v4.676c0 .164-.055.3-.164.41a.556.556 0 01-.41.164h-.602a.556.556 0 01-.41-.164.556.556 0 01-.164-.41v-.602h-7v.602c0 .164-.055.3-.164.41a.556.556 0 01-.41.164h-.602a.556.556 0 01-.41-.164.556.556 0 01-.164-.41V10l1.203-3.5c.11-.383.392-.574.848-.574H9.25V4.75h3.5v1.176h1.45c.455 0 .738.191.847.574z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="taxi-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgTaxiActive;

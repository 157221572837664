import * as React from "react";

function SvgPedalbikeDefault(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#pedalbike-default_svg__clip0)">
        <g filter="url(#pedalbike-default_svg__filter0_dd)">
          <path d="M13 22a9 9 0 100-18 9 9 0 000 18z" fill={props.markerColor} />
        </g>
        <path
          d="M16.09 11l-.85-2.34A1.004 1.004 0 0014.3 8H13v1h1.3l.73 2h-2.405l-.18-.5H13v-1h-2.5v1h.875l.91 2.5h-.335a2.465 2.465 0 00-2.325-1.995C8.225 10.935 7 12.1 7 13.5 7 14.9 8.1 16 9.5 16c1.23 0 2.225-.845 2.45-2h2.1a2.465 2.465 0 002.325 1.995c1.4.065 2.625-1.095 2.625-2.5 0-1.4-1.1-2.5-2.5-2.5h-.41V11zm-5.18 3c-.2.585-.745 1-1.41 1-.84 0-1.5-.66-1.5-1.5S8.66 12 9.5 12c.665 0 1.21.415 1.41 1H9.5v1h1.41zm3.14-1h-.7l-.365-1H14.5c-.22.29-.38.625-.45 1zm2.45 2c-.84 0-1.5-.66-1.5-1.5 0-.465.205-.865.525-1.14l.48 1.32.94-.34-.485-1.335c.015 0 .03-.005.045-.005.84 0 1.5.66 1.5 1.5S17.34 15 16.5 15z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="pedalbike-default_svg__clip0">
          <path fill={props.markerColor} d="M0 0h26v26H0z" />
        </clipPath>
        <filter
          id="pedalbike-default_svg__filter0_dd"
          x={0}
          y={2}
          width={26}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgPedalbikeDefault;

import _ from 'lodash'

export function getActiveLocationTypeName(activeLocationTypeFilters, types) {
    if(!activeLocationTypeFilters) {
        return types[0];
    }
    const activeTypes = Object.entries(activeLocationTypeFilters).filter(entry => entry[1]).map(entry => entry[0]);
    const legalLocationType = types.filter(t => activeTypes.includes(t))[0];

    return legalLocationType || types[0];
}

export function getActiveLocationType(activeLocationTypeFilters, types, config) {
    const activeLocationTypeName = getActiveLocationTypeName(activeLocationTypeFilters, types);

    return [...config.locationTypes, ...config.transportationTypes].find( l => l.type === activeLocationTypeName);
}

export function getSingularTypeName(locationTypes, activeLocationTypeFilters, types, language) {
    if(_.some(Object.values(activeLocationTypeFilters), _.identity)) {
        const legalLocationType = getActiveLocationTypeName(activeLocationTypeFilters, types);
        return locationTypes.find(l => l.type === legalLocationType)?.singularName[language];
    }

    return getLocationTypeFromList(locationTypes, types[0])?.singularName[language];
}

export function getLocationTypeFromList(beforeSorting, p) {
    return beforeSorting.find(l => l.type === p);
}

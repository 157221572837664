import * as React from "react";

function SvgShoppingHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M11.07 11.496a2.679 2.679 0 001.93.787c.745 0 1.38-.262 1.904-.787.542-.525.813-1.16.813-1.904h-1.092c0 .44-.16.82-.482 1.142a1.562 1.562 0 01-1.143.483c-.44 0-.821-.161-1.143-.483a1.562 1.562 0 01-.482-1.142h-1.092c0 .745.263 1.38.787 1.904zm3.073-4.139A1.562 1.562 0 0013 6.875c-.44 0-.821.16-1.143.482a1.562 1.562 0 00-.482 1.143h3.25c0-.44-.16-.821-.482-1.143zm2.64 1.143c.288 0 .542.11.762.33.22.22.33.474.33.762v6.5c0 .288-.11.542-.33.762a1.09 1.09 0 01-.762.304H9.217a1.09 1.09 0 01-.762-.305c-.22-.22-.33-.473-.33-.761v-6.5c0-.288.11-.542.33-.762.22-.22.474-.33.762-.33h1.066c0-.745.263-1.38.787-1.904.542-.542 1.185-.813 1.93-.813.745 0 1.38.271 1.904.813.542.524.813 1.16.813 1.904h1.066z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgShoppingHover;

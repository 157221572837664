import * as React from "react";

function SvgSeestadtDefault(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#seestadt-default_svg__clip0)">
        <g filter="url(#seestadt-default_svg__filter0_dd)">
          <path d="M13 22a9 9 0 100-18 9 9 0 000 18z" fill={props.markerColor} />
        </g>
        <path
          d="M15.5 14.5l.775.775c-.48.845-1.665 1.52-2.775 1.685V12.5H15v-1h-1.5v-.59c.58-.21 1-.76 1-1.41 0-.825-.675-1.5-1.5-1.5s-1.5.675-1.5 1.5c0 .65.42 1.2 1 1.41v.59H11v1h1.5v4.46c-1.11-.165-2.295-.84-2.775-1.685l.775-.775-2-1.5v1.5c0 1.94 2.46 3.5 4.5 3.5s4.5-1.56 4.5-3.5V13l-2 1.5zM13 9c.275 0 .5.225.5.5s-.225.5-.5.5a.501.501 0 01-.5-.5c0-.275.225-.5.5-.5z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="seestadt-default_svg__clip0">
          <path fill={props.markerColor} d="M0 0h26v26H0z" />
        </clipPath>
        <filter
          id="seestadt-default_svg__filter0_dd"
          x={0}
          y={2}
          width={26}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgSeestadtDefault;

import * as React from "react";

function SvgShuttleDefault(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#shuttle-default_svg__clip0)">
        <g filter="url(#shuttle-default_svg__filter0_dd)">
          <path d="M13 22a9 9 0 100-18 9 9 0 000 18z" fill={props.markerColor} />
        </g>
        <path
          d="M15.125 11.967h2.717l-2.184-2.184h-.533v2.184zm.762 3.275c.169.152.364.229.584.229a.76.76 0 00.558-.229.803.803 0 00.254-.584.723.723 0 00-.254-.558.723.723 0 00-.558-.254.803.803 0 00-.584.254.76.76 0 00-.229.558c0 .22.076.415.229.584zm-1.854-3.275V9.783h-2.158v2.184h2.158zm-4.367 3.275a.79.79 0 00.584.229.791.791 0 00.584-.229.848.848 0 00.229-.584.76.76 0 00-.229-.558.75.75 0 00-.584-.254.75.75 0 00-.584.254.76.76 0 00-.229.558c0 .22.077.415.229.584zm-1.041-3.275h2.158V9.783H8.625v2.184zm7.592-3.25l3.25 3.25v2.691h-1.371c0 .44-.161.821-.483 1.143a1.562 1.562 0 01-1.142.482c-.44 0-.821-.16-1.143-.482a1.562 1.562 0 01-.482-1.143h-2.971c0 .44-.16.821-.482 1.143a1.562 1.562 0 01-1.143.482c-.44 0-.821-.16-1.143-.482a1.562 1.562 0 01-.482-1.143H7.533V9.783c0-.304.102-.558.305-.762a1.12 1.12 0 01.787-.304h7.592z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="shuttle-default_svg__clip0">
          <path fill={props.markerColor} d="M0 0h26v26H0z" />
        </clipPath>
        <filter
          id="shuttle-default_svg__filter0_dd"
          x={0}
          y={2}
          width={26}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgShuttleDefault;

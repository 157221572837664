import * as React from "react";

function SvgCafeActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#cafe-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M5.176 15.75v-1.176h10.5v1.176h-10.5zm10.5-7.574v-1.75H14.5v1.75h1.176zm0-2.926c.328 0 .601.118.82.355.219.22.328.493.328.82v1.75c0 .329-.11.602-.328.821a1.113 1.113 0 01-.82.328H14.5v1.75a2.33 2.33 0 01-.684 1.668 2.238 2.238 0 01-1.64.684h-3.5a2.329 2.329 0 01-1.668-.684 2.33 2.33 0 01-.684-1.668V5.25h9.352z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="cafe-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCafeActive;

import * as React from "react";

function SvgParkDefault(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#park-default_svg__clip0)">
        <g filter="url(#park-default_svg__filter0_dd)">
          <path d="M13 22a9 9 0 100-18 9 9 0 000 18z" fill={props.markerColor} />
        </g>
        <path
          d="M9.521 11.738a.791.791 0 01-.584.229.791.791 0 01-.583-.229.848.848 0 01-.229-.584.76.76 0 01.229-.558.75.75 0 01.584-.254.75.75 0 01.583.254.76.76 0 01.229.558c0 .22-.076.415-.229.584zm8.989-.761c0 .964-.322 1.802-.965 2.513a3.75 3.75 0 01-2.387 1.244v2.108h1.625v1.066H8.125v-2.691h-.533v-2.184a.517.517 0 01.533-.533H9.75a.517.517 0 01.533.533v2.184H9.75v1.625h4.342v-2.133a3.782 3.782 0 01-2.285-1.27 3.735 3.735 0 01-.889-2.462c0-1.05.372-1.947 1.117-2.692.745-.745 1.642-1.117 2.692-1.117 1.049 0 1.938.372 2.666 1.117.744.745 1.117 1.642 1.117 2.692z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="park-default_svg__clip0">
          <path fill={props.markerColor} d="M0 0h26v26H0z" />
        </clipPath>
        <filter
          id="park-default_svg__filter0_dd"
          x={0}
          y={2}
          width={26}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgParkDefault;

import * as React from "react";

function SvgSeestadtActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#seestadt-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M14.667 12l1.033 1.033c-.64 1.127-2.22 2.027-3.7 2.247V9.333h2V8h-2v-.787a2.007 2.007 0 001.333-1.88c0-1.1-.9-2-2-2s-2 .9-2 2c0 .867.56 1.6 1.334 1.88V8h-2v1.333h2v5.947c-1.48-.22-3.06-1.12-3.7-2.247L8 12l-2.667-2v2c0 2.587 3.28 4.667 6 4.667s6-2.08 6-4.667v-2l-2.666 2zm-3.334-7.333c.367 0 .667.3.667.666 0 .367-.3.667-.667.667a.669.669 0 01-.666-.667c0-.366.3-.666.666-.666z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="seestadt-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgSeestadtActive;

import {LanguageContext} from "./hotelmapy";
import {useContext} from "react";
import deMessages from "../lang/de.json";
import enMessages from '../lang/en.json'
import getUserLocale from "get-user-locale";


const MESSAGES = {'de': deMessages, 'en': enMessages};
const AVAILABLE_LANGUAGES = Object.keys(MESSAGES)

function TranslateValue({values}) {
    const translateValue = useTranslateValue();

    return translateValue(values)
}

export function getMessages() {
    return MESSAGES[getUserLanguage()];
}

export function getUserLanguage() {
    const websiteLanguage = new URLSearchParams(window.location.search).get('lang');
    const userLanguage = getUserLocale().substr(0,2);
    const language = websiteLanguage || userLanguage;

    return AVAILABLE_LANGUAGES.includes(language) ? language : 'en';
}

export function useTranslateValue() {
    const language = useContext(LanguageContext);

    return (values) => {
        if(!values) {
            debugger;
            return '';
        }
        return values[language] || values['de'];
    }
}

export default TranslateValue;
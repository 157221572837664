import * as React from "react";

function SvgPoiHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M11.219 14.781c.5.485 1.094.727 1.781.727.688 0 1.273-.242 1.758-.727.5-.5.75-1.094.75-1.781 0-.688-.25-1.273-.75-1.758-.485-.5-1.07-.75-1.758-.75s-1.281.25-1.781.75A2.394 2.394 0 0010.492 13c0 .688.242 1.281.727 1.781zm.281-6.773h3l.914.984h1.594c.265 0 .492.102.68.305a.966.966 0 01.304.703v6c0 .266-.101.5-.305.703a.892.892 0 01-.68.305H8.993a.966.966 0 01-.703-.305A1.006 1.006 0 018.008 16v-6c0-.266.094-.5.281-.703a.966.966 0 01.703-.305h1.594l.914-.984zm.375 6.117A1.534 1.534 0 0111.406 13c0-.438.156-.813.469-1.125A1.534 1.534 0 0113 11.406c.438 0 .813.156 1.125.469.313.313.469.688.469 1.125 0 .438-.156.813-.469 1.125a1.534 1.534 0 01-1.125.469c-.438 0-.813-.156-1.125-.469z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgPoiHover;

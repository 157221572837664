import * as React from "react";

function SvgPedalbikeActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#pedalbike-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M14.605 8.75l-.992-2.925C13.444 5.331 13.007 5 12.517 5H11v1.25h1.517l.851 2.5h-2.806l-.21-.625H11v-1.25H8.083v1.25h1.021l1.062 3.125h-.391c-.257-1.394-1.348-2.425-2.713-2.494C5.43 8.67 4 10.125 4 11.875 4 13.625 5.283 15 6.917 15c1.435 0 2.595-1.056 2.858-2.5h2.45c.257 1.394 1.348 2.425 2.713 2.494C16.57 15.075 18 13.625 18 11.869c0-1.75-1.283-3.125-2.917-3.125h-.478v.006zM8.562 12.5c-.234.731-.87 1.25-1.645 1.25-.98 0-1.75-.825-1.75-1.875S5.937 10 6.917 10c.776 0 1.411.519 1.645 1.25H6.917v1.25h1.645zm3.663-1.25h-.817L10.983 10h1.767a3.063 3.063 0 00-.525 1.25zm2.858 2.5c-.98 0-1.75-.825-1.75-1.875 0-.581.24-1.081.613-1.425l.56 1.65 1.096-.425-.565-1.669c.017 0 .035-.006.052-.006.98 0 1.75.825 1.75 1.875s-.776 1.875-1.756 1.875z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="pedalbike-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPedalbikeActive;

import * as React from "react";

function SvgSeestadtHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.75 13c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M15.5 13.5l.775.775c-.48.845-1.665 1.52-2.775 1.685V11.5H15v-1h-1.5v-.59c.58-.21 1-.76 1-1.41 0-.825-.675-1.5-1.5-1.5s-1.5.675-1.5 1.5c0 .65.42 1.2 1 1.41v.59H11v1h1.5v4.46c-1.11-.165-2.295-.84-2.775-1.685l.775-.775-2-1.5v1.5c0 1.94 2.46 3.5 4.5 3.5s4.5-1.56 4.5-3.5V12l-2 1.5zM13 8c.275 0 .5.225.5.5s-.225.5-.5.5a.501.501 0 01-.5-.5c0-.275.225-.5.5-.5z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgSeestadtHover;

import React, {useEffect, useRef, useState} from 'react'
import classNames from 'classnames'
import TranslateValue from "./translated_value";

function LocationType({ locationType, toggleLocationTypeFilter, active, type }) {
  return (
    <li
      className='filter-bar__location-type-container'
      onClick={() => toggleLocationTypeFilter(type)}
    >
      <div
        className={classNames('filter-bar__location-type', {
          'filter-bar__location-type--active': active,
        })}
      >
        <div className='filter-bar__location-type-icon'>
          {locationType.icon}
        </div>
        <TranslateValue values={locationType.name}/>
      </div>
    </li>
  )
}

function FilterBar({
  locationTypes,
  toggleLocationTypeFilter,
  activeLocationTypeFilters,
}) {
  const filterBarRef = useRef();
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  useEffect(() => {
    window.addEventListener('resize', () => updateButtons(0));
  })

  useEffect(() => {
    if(filterBarRef.current) {
      updateButtons(0);
      filterBarRef.current.addEventListener('scroll', () => {
        updateButtons(filterBarRef.current.scrollLeft)
      })
    }
  }, [filterBarRef.current])

  function scrollLeft() {
    const newPosition = filterBarRef.current.scrollLeft - filterBarRef.current.clientWidth;
    filterBarRef.current.scrollTo({left: newPosition, behavior: 'smooth'});
    updateButtons(newPosition)
  }

  function scrollRight() {
    const newPosition = filterBarRef.current.scrollLeft + filterBarRef.current.clientWidth;
    filterBarRef.current.scrollTo({left: newPosition, behavior: 'smooth'});
    updateButtons(newPosition)
  }

  function updateShowLeftButton(newPosition) {
    if(!filterBarRef.current) {
      return false;
    }
    return setShowLeftButton(newPosition > 25);

  }

  function updateShowRightButton(newPosition) {
    if(!filterBarRef.current) {
      return false;
    }
    return setShowRightButton(newPosition < ((filterBarRef.current.scrollWidth - filterBarRef.current.clientWidth) - 25));
  }

  function updateButtons(newPosition)  {
    updateShowLeftButton(newPosition)
    updateShowRightButton(newPosition)
  }

  return (
      <React.Fragment>
        <div className="filter-bar">
        {showLeftButton && <div className="filter-bar__scroll-button filter-bar__scroll-button--left" onClick={scrollLeft}></div>}
        <ul className='filter-bar__container' ref={filterBarRef}>
          {locationTypes.map((entry) => (
            <LocationType
              active={activeLocationTypeFilters[entry.type]}
              key={entry.name.de}
              toggleLocationTypeFilter={toggleLocationTypeFilter}
              locationType={entry}
              type={entry.type}
            />
          ))}
        </ul>
        {showRightButton && <div className="filter-bar__scroll-button filter-bar__scroll-button--right" onClick={scrollRight}></div>}
        </div>
      </React.Fragment>
  )
}

export default FilterBar

import * as React from "react";

function SvgAirportDefault(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#airport-default_svg__clip0)">
        <g filter="url(#airport-default_svg__filter0_dd)">
          <path d="M13 22a9 9 0 100-18 9 9 0 000 18z" fill={props.markerColor} />
        </g>
        <path
          d="M18.125 14.658l-4.342-1.345v2.97l1.092.813v.812l-1.904-.533-1.88.533v-.812l1.067-.813v-2.97l-4.316 1.345v-1.066l4.316-2.717v-2.97a.76.76 0 01.229-.56.803.803 0 01.584-.253c.22 0 .406.084.558.254.17.152.254.338.254.558v2.971l4.342 2.717v1.066z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="airport-default_svg__clip0">
          <path fill={props.markerColor} d="M0 0h26v26H0z" />
        </clipPath>
        <filter
          id="airport-default_svg__filter0_dd"
          x={0}
          y={2}
          width={26}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgAirportDefault;

import * as React from "react";

function SvgPoiActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
        fill={props.markerColor}
      />
      <path
        d="M8.922 13.078a2.885 2.885 0 002.078.848c.802 0 1.486-.283 2.05-.848.584-.583.876-1.276.876-2.078 0-.802-.292-1.486-.875-2.05-.565-.584-1.249-.876-2.051-.876s-1.495.292-2.078.875A2.793 2.793 0 008.074 11c0 .802.283 1.495.848 2.078zm.328-7.902h3.5l1.066 1.148h1.86c.31 0 .574.119.793.356.237.237.355.51.355.82v7c0 .31-.118.583-.355.82a1.04 1.04 0 01-.793.356H6.324c-.31 0-.583-.119-.82-.356a1.173 1.173 0 01-.328-.82v-7c0-.31.11-.583.328-.82.237-.237.51-.356.82-.356h1.86L9.25 5.176zm.438 7.136A1.79 1.79 0 019.14 11c0-.51.182-.948.546-1.313A1.79 1.79 0 0111 9.142c.51 0 .948.182 1.313.546.364.365.546.803.546 1.313 0 .51-.182.948-.546 1.313a1.79 1.79 0 01-1.313.546 1.79 1.79 0 01-1.313-.546z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgPoiActive;

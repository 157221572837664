import * as React from "react";

function SvgParkingHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M13.66 11.967c.288 0 .533-.11.736-.33.22-.22.33-.474.33-.762 0-.288-.11-.542-.33-.762a.966.966 0 00-.736-.33h-1.752v2.184h1.752zm-.127-4.342a3.07 3.07 0 012.285.965 3.07 3.07 0 01.965 2.285c0 .897-.321 1.667-.965 2.31-.626.627-1.388.94-2.285.94h-1.625v3.25H9.75v-9.75h3.783z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgParkingHover;

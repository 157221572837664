import * as React from "react";

function SvgTrainDefault(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#train-default_svg__clip0)">
        <g filter="url(#train-default_svg__filter0_dd)">
          <path d="M13 22a9 9 0 100-18 9 9 0 000 18z" fill={props.markerColor} />
        </g>
        <path
          d="M14.854 14.988a.791.791 0 00.584.229.791.791 0 00.583-.229.848.848 0 00.229-.584.76.76 0 00-.229-.558.75.75 0 00-.584-.254.75.75 0 00-.583.254.76.76 0 00-.229.558c0 .22.076.415.229.584zm-1.32-3.58h2.716V9.25h-2.717v2.158zm-1.067 0V9.25H9.75v2.158h2.717zm-2.488 3.58a.791.791 0 00.584.229.791.791 0 00.583-.229.848.848 0 00.229-.584.76.76 0 00-.229-.558.75.75 0 00-.584-.254.75.75 0 00-.583.254.76.76 0 00-.229.558c0 .22.076.415.229.584zM13 7.092c1.405 0 2.48.144 3.225.431.744.288 1.117.864 1.117 1.727v5.154c0 .525-.186.974-.559 1.346a1.879 1.879 0 01-1.346.533l.813.813v.279h-1.092l-1.066-1.092h-2.057l-1.066 1.092H9.75v-.28l.813-.812c-.525 0-.974-.178-1.346-.533a1.834 1.834 0 01-.559-1.346V9.25c0-.457.119-.838.356-1.143.237-.304.575-.524 1.015-.66a7.938 7.938 0 011.346-.279c.457-.05.999-.076 1.625-.076z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="train-default_svg__clip0">
          <path fill={props.markerColor} d="M0 0h26v26H0z" />
        </clipPath>
        <filter
          id="train-default_svg__filter0_dd"
          x={0}
          y={2}
          width={26}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgTrainDefault;

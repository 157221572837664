import * as React from "react";

function SvgShuttleActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#shuttle-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M12.75 10.426h2.926l-2.352-2.352h-.574v2.352zm.82 3.527a.912.912 0 00.63.246.818.818 0 00.6-.246.864.864 0 00.274-.629.779.779 0 00-.273-.601.779.779 0 00-.602-.274.864.864 0 00-.629.274.818.818 0 00-.246.601c0 .237.082.447.246.63zm-1.996-3.527V8.074H9.25v2.352h2.324zm-4.703 3.527a.852.852 0 00.629.246.852.852 0 00.629-.246.913.913 0 00.246-.629.818.818 0 00-.246-.601.808.808 0 00-.629-.274.808.808 0 00-.629.274.818.818 0 00-.246.601c0 .237.082.447.246.63zM5.75 10.426h2.324V8.074H5.75v2.352zm8.176-3.5l3.5 3.5v2.898h-1.477c0 .474-.173.884-.52 1.23-.346.347-.756.52-1.23.52s-.884-.173-1.23-.52a1.682 1.682 0 01-.52-1.23H9.25c0 .474-.173.884-.52 1.23-.346.347-.756.52-1.23.52s-.884-.173-1.23-.52a1.682 1.682 0 01-.52-1.23H4.574v-5.25c0-.328.11-.601.328-.82.237-.219.52-.328.848-.328h8.176z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="shuttle-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgShuttleActive;

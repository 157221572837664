import * as React from "react";

function SvgAirportActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#airport-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M16.75 12.324l-4.676-1.449v3.2l1.176.874v.875l-2.05-.574-2.024.574v-.875l1.148-.875v-3.199l-4.648 1.45v-1.15l4.648-2.925v-3.2c0-.236.082-.437.246-.6a.864.864 0 01.63-.274c.236 0 .437.09.6.273a.779.779 0 01.274.602V8.25l4.676 2.926v1.148z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="airport-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAirportActive;

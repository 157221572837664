import React, {useContext, useEffect, useRef, useState} from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import Swiper from 'swiper'
import {getActiveLocationType, getActiveLocationTypeName, getLocationTypeFromList, getSingularTypeName} from "./utils";
import TranslateValue from "./translated_value";
import {FormattedMessage, useIntl} from "react-intl";
import {LanguageContext} from "./hotelmapy";
import Color from "color";

function Location({
                      type,
                      icon,
                      name, locationTypes,
                      distance,
                      walkingMinutes,
                      drivingMinutes,
                      selected,
                      locationSelected,
                      onDesktopLocationSheetMouseLeave,
                      onDesktopLocationSheetMouseEnter,
                      setDisplayLocationInfoOnMobile,
                      onMobile,
                      activeLocationTypeFilters,
                      runningDistance,
                      runningMinutes, config
                  }) {
    const [highlighted, setHighlighted] = useState(false)
    const language = useContext(LanguageContext);
    const {formatNumber} = useIntl()

    const locationClasses = classNames('locations__location', {
        'locations__location--selected': selected,
        'locations__location--highlighted': highlighted,
        'swiper-slide': onMobile,
    })

    function onMouseEnter() {
        onDesktopLocationSheetMouseEnter()
        setHighlighted(true)
    }

    function onMouseLeave() {
        onDesktopLocationSheetMouseLeave()
        setHighlighted(false)
    }

    const highDistance = distance >= 1000;

    function formatDistance(distance) {
        return `${formatNumber(highDistance ? distance / 1000 : distance)} ${highDistance ? 'km' : 'm'}`
    }

    function getLocationTypeTitleColor() {
        const locationType = getLocationTypeFromList(locationTypes, getActiveLocationTypeName(activeLocationTypeFilters, type));

        return config.theme.locationTypeTitleColor === undefined ? locationType.markerColors?.defaultMarkerColor : config.theme.locationTypeTitleColor;
    }

    function getLocationIconStyle() {
        const background = getLocationTypeTitleColor();
        return {background: background};
    }


    function getLocationCategoryStyle() {
      return {color: getLocationTypeTitleColor(config.theme)};
    }

    return (
        <div
            className={locationClasses}
            onClick={locationSelected}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            id={_.camelCase(name)}
        >
            <div className='locations__information'>
                <div className='locations__title'>
                    <div
                        className='locations__type' style={getLocationCategoryStyle()}>
                        <div className='locations__icon' style={getLocationIconStyle()}>{icon}</div>
                        {getSingularTypeName(locationTypes, activeLocationTypeFilters, type, language)}</div>
                    <div className='locations__name'><TranslateValue values={name}/></div>
                </div>
                <div className='locations__reachability'>
                    <React.Fragment>
                        {distance && (
                            <div className='locations__reachability-walking-distance'>
                                {formatDistance(distance)}
                            </div>)}
                        {walkingMinutes && walkingMinutes <= 120 &&
                        <div className='locations__reachability-walking-minutes'>
                            {walkingMinutes} Min.
                        </div>}
                        {drivingMinutes && drivingMinutes >= 5 && (
                            <div className='locations__reachability-driving-minutes'>
                                {drivingMinutes} Min.
                            </div>
                        )}
                        {runningDistance &&
                        <div className='locations__reachability-running-distance'>
                            {formatDistance(runningDistance)}
                        </div>}
                        {runningMinutes &&
                        <div className='locations__reachability-running-minutes'>
                            {runningMinutes} Min.
                        </div>}
                    </React.Fragment>
                </div>
                <button
                    className='locations__information-button'
                    onClick={() => setDisplayLocationInfoOnMobile(true)}
                >
                    <div className='locations__information-button-shape'>
                        <div className='locations__information-button-icon'>more_vert</div>
                        <FormattedMessage id="MORE"/>
                    </div>
                </button>
            </div>
        </div>
    )
}

function Locations({
                       locations,
                       locationSelected,
                       selectedLocation,
                       onDesktopLocationSheetMouseLeave,
                       onDesktopLocationSheetMouseEnter,
                       setDisplayLocationInfoOnMobile, activeLocationTypeFilters, config
                   }) {
    const onMobileMediaWatch = window.matchMedia('(max-width: 575px)')
    const [onMobile, setOnMobile] = useState(onMobileMediaWatch.matches)
    const [currentIndex, setCurrentIndex] = useState(0)
    const sliderInstanceRef = useRef()
    const sliderDomRef = useRef()

    useEffect(() => {
        if (sliderInstanceRef.current && selectedLocation) {
            const locationIndex = locations.findIndex(l => l === selectedLocation)
            if (locationIndex !== currentIndex) {
                sliderInstanceRef.current.slideTo(locationIndex)
            }
        }
    }, [selectedLocation, locations])

    useEffect(() => {
        if (!onMobileMediaWatch.matches || !sliderDomRef.current) {
            return
        }

        if (sliderInstanceRef.current) {
            try {
                sliderInstanceRef.current.destroy(false, true)
            } catch (e) {
                console.log(e)
            }
        }

        sliderInstanceRef.current = new Swiper(sliderDomRef.current, {
            slidesPerView: 'auto',
            centeredSlides: true,
            iOSEdgeSwipeDetection: true,
            slideToClickedSlide: true,
            resistanceRatio: 1,
            touchRatio: 1,
            iOSEdgeSwipeThreshold: 1,
            speed: 280,
        })
        sliderInstanceRef.current.on('slideNextTransitionEnd', () => {
            setCurrentIndex(sliderInstanceRef.current.realIndex)
        })
        sliderInstanceRef.current.on('slidePrevTransitionEnd', () => {
            setCurrentIndex(sliderInstanceRef.current.realIndex)
        })
        return () => sliderInstanceRef.de
    }, [locations, sliderDomRef.current])

    useEffect(() => {
        if (onMobile) {
            locationSelected(locations[currentIndex])()
        }
    }, [currentIndex, locations])

    onMobileMediaWatch.addListener(e => setOnMobile(e.matches))

    return (
        <div
            className={classNames('locations', {'swiper-container': onMobile})}
            ref={sliderDomRef}
        >
            <div
                className={classNames('locations__location-container', {
                    'swiper-wrapper': onMobile,
                })}
            >
                {locations.map(location => (
                    <Location
                        {...location}
                        icon={getActiveLocationType(activeLocationTypeFilters, location.type, config).icon}
                        locationTypes={[...config.locationTypes, ...config.transportationTypes]}
                        key={location.name.de}
                        locationSelected={locationSelected(location)}
                        selected={location === selectedLocation}
                        onDesktopLocationSheetMouseLeave={onDesktopLocationSheetMouseLeave(
                            location,
                        )}
                        onDesktopLocationSheetMouseEnter={onDesktopLocationSheetMouseEnter(
                            location,
                        )}
                        setDisplayLocationInfoOnMobile={setDisplayLocationInfoOnMobile}
                        onMobile={onMobile}
                        activeLocationTypeFilters={activeLocationTypeFilters}
                        config={config}
                    />
                ))}
            </div>
        </div>
    )
}

export default Locations

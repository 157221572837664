import * as React from "react";

function SvgBusHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M16.25 11.467V8.75h-6.5v2.717h6.5zm-1.396 3.021a.791.791 0 00.584.229.791.791 0 00.583-.229.848.848 0 00.229-.584.76.76 0 00-.229-.558.75.75 0 00-.584-.254.75.75 0 00-.583.254.76.76 0 00-.229.558c0 .22.076.415.229.584zm-4.875 0a.791.791 0 00.584.229.791.791 0 00.583-.229.848.848 0 00.229-.584.76.76 0 00-.229-.558.75.75 0 00-.584-.254.75.75 0 00-.583.254.76.76 0 00-.229.558c0 .22.076.415.229.584zm-1.32-.33V8.75c0-.863.372-1.439 1.116-1.727.745-.287 1.82-.431 3.225-.431s2.48.144 3.225.431c.744.288 1.117.864 1.117 1.727v5.408c0 .474-.186.88-.559 1.219v.965a.517.517 0 01-.533.533h-.533a.607.607 0 01-.407-.152.517.517 0 01-.152-.381v-.559h-4.316v.559c0 .152-.06.28-.178.38a.517.517 0 01-.38.153H9.75a.517.517 0 01-.533-.533v-.965c-.373-.339-.559-.745-.559-1.219z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgBusHover;

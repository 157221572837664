import React, {useEffect, useRef, useState} from "react";

function FallBackMap() {
    const mapRef = useRef()
    const googleMap = useRef()
    const [config, setConfig] = useState()
    const hotelMarker = useRef()
    const iconBase = '/svg_icons'

    useEffect(() => loadConfig(), [])
    useEffect(() => {
        if (config) {
            createMap(config)
            createHotelMarker()
        }
    }, [config])

    function getConfigPath() {
        const hotelId = new URLSearchParams(window.location.search).get('hotel');

        if (hotelId) {
            return `${hotelId}_config.json`;
        }

        return '/demo_config.json';
    }

    function createHotelMarker() {
        hotelMarker.current = new window.google.maps.Marker({
            position: {
                lat: config.hotel.location.lat,
                lng: config.hotel.location.lng,
            },
            map: googleMap.current,
            icon: iconSrc(config.markers.hotels),
            zIndex: window.google.maps.Marker.MAX_ZINDEX + 3,
        })
    }

    function loadConfig() {
        fetch(getConfigPath()).then(response =>
            response.json().then(json => {
                setConfig(json)
            }),
        )
    }

    function createMap() {
        googleMap.current = new window.google.maps.Map(mapRef.current, {
            zoom: 14,
          //  styles: config.mapConfig,
            center: {
                lat: config.hotel.location.lat,
                lng: config.hotel.location.lng,
            },
            disableDefaultUI: true,
        })
    }
    function createHotelMarker() {
        hotelMarker.current = new window.google.maps.Marker({
            position: {
                lat: config.hotel.location.lat,
                lng: config.hotel.location.lng,
            },
            map: googleMap.current,
            icon: iconSrc(config.markers.hotels),
            zIndex: window.google.maps.Marker.MAX_ZINDEX + 3,
        })
    }

    function iconSrc(iconName) {
        return `${iconBase}/${iconName}.png`
    }

    return <div className='fallback-map'><div className='map__google-map' id='map' ref={mapRef}/></div>
}

export default FallBackMap;
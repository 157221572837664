import * as React from "react";

function SvgEntertainmentDefault(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#entertainment-default_svg__clip0)">
        <g filter="url(#entertainment-default_svg__filter0_dd)">
          <path d="M13 22a9 9 0 100-18 9 9 0 000 18z" fill={props.markerColor} />
        </g>
        <path
          d="M14.93 15.59l-.584-2.21 1.777-1.472-2.285-.127L13 9.648l-.838 2.133-2.31.127 1.802 1.473-.584 2.209L13 14.346l1.93 1.244zm2.716-3.352a1.09 1.09 0 00-.304.762c0 .288.101.542.305.762.22.22.473.33.761.33v2.158c0 .288-.11.542-.33.762a.966.966 0 01-.736.33H8.658c-.288 0-.541-.11-.762-.33a1.09 1.09 0 01-.304-.762v-2.158c.304 0 .558-.102.762-.305A1.12 1.12 0 008.658 13c0-.288-.11-.542-.33-.762a.966.966 0 00-.736-.33V9.75c0-.288.101-.542.304-.762.22-.22.474-.33.762-.33h8.684c.288 0 .533.11.736.33.22.22.33.474.33.762v2.158c-.288 0-.542.11-.762.33z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="entertainment-default_svg__clip0">
          <path fill={props.markerColor} d="M0 0h26v26H0z" />
        </clipPath>
        <filter
          id="entertainment-default_svg__filter0_dd"
          x={0}
          y={2}
          width={26}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgEntertainmentDefault;

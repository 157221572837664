import * as React from "react";

function SvgShuttleHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M14.625 11.467h2.717l-2.184-2.184h-.533v2.184zm.762 3.275c.169.152.364.229.584.229a.76.76 0 00.558-.229.803.803 0 00.254-.584.723.723 0 00-.254-.558.723.723 0 00-.558-.254.803.803 0 00-.584.254.76.76 0 00-.229.558c0 .22.076.415.229.584zm-1.854-3.275V9.283h-2.158v2.184h2.158zm-4.367 3.275a.791.791 0 00.584.229.791.791 0 00.584-.229.848.848 0 00.229-.584.76.76 0 00-.229-.558.75.75 0 00-.584-.254.75.75 0 00-.584.254.76.76 0 00-.229.558c0 .22.077.415.229.584zm-1.041-3.275h2.158V9.283H8.125v2.184zm7.592-3.25l3.25 3.25v2.691h-1.371c0 .44-.161.821-.483 1.143a1.562 1.562 0 01-1.142.482c-.44 0-.821-.16-1.143-.482a1.562 1.562 0 01-.482-1.143h-2.971c0 .44-.16.821-.482 1.143a1.562 1.562 0 01-1.143.482c-.44 0-.821-.16-1.143-.482a1.562 1.562 0 01-.482-1.143H7.033V9.283c0-.304.102-.558.305-.762a1.12 1.12 0 01.787-.304h7.592z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgShuttleHover;

import './App.css';
import Hotelmapy from "./app/hotelmapy";
import React, {useState} from "react";
import {IntlProvider} from 'react-intl'
import {getMessages, getUserLanguage} from "./app/translated_value";
import FallBackMap from "./app/fallback_map";

function App() {
    if(window.Modernizr.cssgrid && window.Modernizr.flexbox ) {
        return <IntlProvider messages={getMessages()} locale={getUserLanguage()}>
            <Hotelmapy/>
        </IntlProvider>;
    } else {
        return <FallBackMap/>
    }

}

export default App;

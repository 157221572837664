import * as React from "react";

function SvgBusDefault(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#bus-default_svg__clip0)">
        <g filter="url(#bus-default_svg__filter0_dd)">
          <path d="M13 22a9 9 0 100-18 9 9 0 000 18z" fill={props.markerColor} />
        </g>
        <path
          d="M16.25 11.967V9.25h-6.5v2.717h6.5zm-1.396 3.021a.791.791 0 00.584.229.791.791 0 00.583-.229.848.848 0 00.229-.584.76.76 0 00-.229-.558.75.75 0 00-.584-.254.75.75 0 00-.583.254.76.76 0 00-.229.558c0 .22.076.415.229.584zm-4.875 0a.791.791 0 00.584.229.791.791 0 00.583-.229.848.848 0 00.229-.584.76.76 0 00-.229-.558.75.75 0 00-.584-.254.75.75 0 00-.583.254.76.76 0 00-.229.558c0 .22.076.415.229.584zm-1.32-.33V9.25c0-.863.372-1.439 1.116-1.727.745-.287 1.82-.431 3.225-.431s2.48.144 3.225.431c.744.288 1.117.864 1.117 1.727v5.408c0 .474-.186.88-.559 1.219v.965a.517.517 0 01-.533.533h-.533a.607.607 0 01-.407-.152.517.517 0 01-.152-.381v-.559h-4.316v.559c0 .152-.06.28-.178.38a.517.517 0 01-.38.153H9.75a.517.517 0 01-.533-.533v-.965c-.373-.339-.559-.745-.559-1.219z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="bus-default_svg__clip0">
          <path fill={props.markerColor} d="M0 0h26v26H0z" />
        </clipPath>
        <filter
          id="bus-default_svg__filter0_dd"
          x={0}
          y={2}
          width={26}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgBusDefault;

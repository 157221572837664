import React from 'react'
import classNames from 'classnames'
import {FormattedMessage} from "react-intl";

function ActionBar({locationPhone, locationURL, locationLat, locationLong, hotelLocation, locationTypes, route}) {
    const baseURL = 'https://'

    function runningRoute() {
        return locationTypes.includes("runningRoute");
    }

    function getUrl(locationURL) {
        if (locationURL && !locationURL.startsWith("http")) {
            return baseURL.concat(locationURL);
        }
        return locationURL;
    }

    function phoneNumberExists() {
        return locationPhone === undefined || locationPhone.length === 0
    }

    function URLExists() {
        return locationURL === undefined || locationURL.length === 0
    }

    function routeURL() {
        if (locationLat) {
            return `https://www.google.de/maps/dir/${hotelLocation.lat},${hotelLocation.lng}/${locationLat},${locationLong}`;
        } else if(route) {
            const origin = route[0];
            const destination = route[route.length - 1];
            const waypoints = [...route];
            waypoints.shift();
            waypoints.pop();

            return `https://www.google.de/maps/dir/?api=1&origin=${origin[1]},${origin[0]}&destination=${destination[1]},${destination[0]}&waypoints=${waypoints.map(step => `${step[1]},${step[0]}`).join('%7C')}&travelmode=walking`;
        }
    }

    return (
        <div className='action-bar'>
            {!runningRoute() &&
            <a
                href={'tel:'.concat(locationPhone)}
                target="_parent"
                className={classNames('action-bar__action', {
                    'action-bar__action--disabled': phoneNumberExists(),
                })}
            >
                <div className='action-bar__action-icon'>phone</div>
                <div className='action-bar__action-label'><FormattedMessage id="CALL"/></div>
            </a>}

            {!runningRoute() &&
            <a
                href={getUrl(locationURL)}
                target='_blank'
                rel='noopener noreferrer'
                className={classNames('action-bar__action', {
                    'action-bar__action--disabled': URLExists(),
                })}
            >
                <div className='action-bar__action-icon'>public</div>
                <div className='action-bar__action-label'>Webseite</div>
            </a>}


            {routeURL() &&
            <a
              href={routeURL()}
              target='_blank'
              rel='noopener noreferrer'
              className='action-bar__action'
            >
                <div className='action-bar__action-icon'>directions</div>
                <div className='action-bar__action-label'>Route</div>
            </a>
            }
        </div>
    )
}

export default ActionBar

import * as React from "react";

function SvgBusActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#bus-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M14.5 10.426V7.5h-7v2.926h7zm-1.504 3.254a.852.852 0 00.629.246.852.852 0 00.629-.246.912.912 0 00.246-.63.818.818 0 00-.246-.6.808.808 0 00-.629-.274.808.808 0 00-.629.273.818.818 0 00-.246.602c0 .237.082.446.246.629zm-5.25 0a.852.852 0 00.629.246.852.852 0 00.629-.246.913.913 0 00.246-.63.818.818 0 00-.246-.6.808.808 0 00-.629-.274.808.808 0 00-.629.273.818.818 0 00-.246.602c0 .237.082.446.246.629zm-1.422-.356V7.5c0-.93.401-1.55 1.203-1.86.802-.31 1.96-.464 3.473-.464s2.67.155 3.473.465c.802.31 1.203.93 1.203 1.859v5.824c0 .51-.2.948-.602 1.313v1.039c0 .164-.054.3-.164.41a.557.557 0 01-.41.164h-.574a.653.653 0 01-.438-.164.556.556 0 01-.164-.41v-.602H8.676v.602c0 .164-.064.3-.192.41a.556.556 0 01-.41.164H7.5a.556.556 0 01-.41-.164.556.556 0 01-.164-.41v-1.04c-.401-.364-.602-.801-.602-1.312z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="bus-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgBusActive;

import * as React from "react";

function SvgAirportHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.75 13c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M18.375 14.658l-4.342-1.345v2.97l1.092.813v.812l-1.904-.533-1.88.533v-.812l1.067-.813v-2.97l-4.316 1.345v-1.066l4.316-2.717v-2.97a.76.76 0 01.229-.56.803.803 0 01.584-.253c.22 0 .406.084.558.254.17.152.254.338.254.558v2.971l4.342 2.717v1.066z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgAirportHover;

import * as React from "react";

function SvgParkActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#park-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M7.254 9.68a.852.852 0 01-.629.246.852.852 0 01-.629-.246.913.913 0 01-.246-.63c0-.236.082-.437.246-.6a.808.808 0 01.629-.274c.255 0 .465.09.629.273a.818.818 0 01.246.602.913.913 0 01-.246.629zm9.68-.82c0 1.038-.347 1.94-1.04 2.706a4.038 4.038 0 01-2.57 1.34v2.27h1.75v1.148H5.75v-2.898h-.574v-2.352c0-.164.054-.3.164-.41a.556.556 0 01.41-.164H7.5c.164 0 .3.055.41.164.11.11.164.246.164.41v2.352H7.5v1.75h4.676v-2.297a4.073 4.073 0 01-2.461-1.367 4.022 4.022 0 01-.957-2.653c0-1.13.4-2.096 1.203-2.898.802-.802 1.768-1.203 2.898-1.203 1.13 0 2.088.4 2.871 1.203.803.802 1.204 1.768 1.204 2.898z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="park-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgParkActive;

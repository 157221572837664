import * as React from "react";

function SvgPedalbikeHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.75 13c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M16.09 11l-.85-2.34A1.004 1.004 0 0014.3 8H13v1h1.3l.73 2h-2.405l-.18-.5H13v-1h-2.5v1h.875l.91 2.5h-.335a2.465 2.465 0 00-2.325-1.995C8.225 10.935 7 12.1 7 13.5 7 14.9 8.1 16 9.5 16c1.23 0 2.225-.845 2.45-2h2.1a2.465 2.465 0 002.325 1.995c1.4.065 2.625-1.095 2.625-2.5 0-1.4-1.1-2.5-2.5-2.5h-.41V11zm-5.18 3c-.2.585-.745 1-1.41 1-.84 0-1.5-.66-1.5-1.5S8.66 12 9.5 12c.665 0 1.21.415 1.41 1H9.5v1h1.41zm3.14-1h-.7l-.365-1H14.5c-.22.29-.38.625-.45 1zm2.45 2c-.84 0-1.5-.66-1.5-1.5 0-.465.205-.865.525-1.14l.48 1.32.94-.34-.485-1.335c.015 0 .03-.005.045-.005.84 0 1.5.66 1.5 1.5S17.34 15 16.5 15z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgPedalbikeHover;

import * as React from "react";

function SvgRestaurantHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M15.158 9.75c0-.508.271-.99.813-1.447a2.863 2.863 0 011.904-.711v10.816h-1.346v-4.316h-1.37V9.75zm-2.691 1.625V7.592h1.066v3.783a2.14 2.14 0 01-.584 1.498c-.39.406-.871.626-1.447.66v4.875h-1.346v-4.875a2.105 2.105 0 01-1.447-.66 2.14 2.14 0 01-.584-1.498V7.592h1.092v3.783h1.066V7.592h1.092v3.783h1.092z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgRestaurantHover;

import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import Tabs from './tabs'
import GoogleMap from './google_map'
import Locations from './locations'
import LocationDetails from './location_details'
import FilterBar from './filter_bar'
import ArrivalNavigationBar from './navigation_bar'
import Color from "color";
import {getUserLanguage} from "./translated_value";
import {getLocationTypeFromList} from "./utils";
import 'core-js/features/promise'
import 'core-js/features/url-search-params'
import 'whatwg-fetch'

const TABS = [
    {"name": "surrounding", "heading": "SURROUNDING"},
    {"name": "arrival", "heading": "CONNECTION"}
];

export const LanguageContext = React.createContext('de');

function Hotelmapy() {
    const [fullscreen, setFullscreen] = useState(false)
    const [activeTab, setActiveTab] = useState('surrounding')
    const [config, setConfig] = useState()
    const onMobile = window.matchMedia('(max-width: 575px)')
    const onLargeScreen = window.matchMedia('(min-width: 1120px)')
    const [hoveredLocation, setHoveredLocation] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [locations, setLocations] = useState([])

    const [activeLocationTypeFilters, setActiveLocationTypeFilters] = useState([])
    const [
        displayLocationInfoOnMobile,
        setDisplayLocationInfoOnMobile,
    ] = useState()
    const [activeItem, setActiveItem] = useState('')
    const urlParams = new URLSearchParams(window.location.search);
    const preselectedLocationFilters = urlParams.get("locationFilters")?.split(",").map(s => s.trim()).filter(s => s !== "")

    useEffect(() => loadConfig(), [])

    useEffect(() => {
        if (config) {
            selectTab();
            selectActiveArrivalNavigationItem();
            selectLocationTypeFilters();
        }
    }, [config])

    useEffect(() => {
        if (config) {
            setCSSThemingVariables()
        }
    }, [config])

    useEffect(() => {
        if (config) {
            setSelectedLocation(null)
        }
    }, [activeTab, activeLocationTypeFilters])

    useEffect(() => {
        if (config) {
            updateLocations()
        }
    }, [activeLocationTypeFilters, activeTab, activeItem, config])

    function getConfigPath() {
        const hotelId = urlParams.get('hotel');

        if (hotelId) {
            return `${hotelId}_config.json`;
        }

        return '/demo_config.json';
    }

    function adaptLocationFilterOrder(config) {
        if (preselectedLocationFilters && preselectedLocationFilters.length > 0) {
            const beforeSorting = config.locationTypes;
            const afterSorting = [...preselectedLocationFilters.map(p => getLocationTypeFromList(beforeSorting, p)), ...beforeSorting.filter(l => !preselectedLocationFilters.includes(l.type))];
            config.locationTypes = afterSorting;
        }

        return config;
    }

    function addMarkerColorsToTransportationTypes(config) {
        config.transportationTypes.forEach(t => {
            if (!t.markerColors) {
                t.markerColors = config.theme.transportationTypeColors;
            }
        });
        return config;
    }

    function sortLocationsByDistance(config) {
        config.surrounding = _.sortBy(config.surrounding, s => s.distance)
        return config;
    }

    function loadConfig() {
        fetch(getConfigPath()).then(response =>
            response.json().then(json => {
                setConfig(sortLocationsByDistance(addMarkerColorsToTransportationTypes(adaptLocationFilterOrder(json))))
            }),
        )
    }

    function selectTab() {
        const preselectedTab = TABS.find(t => t.name === urlParams.get("view"));
        if (preselectedTab) {
            setActiveTab(preselectedTab.name)
        } else {
            setActiveTab(TABS[0].name)
        }
    }

    function selectActiveArrivalNavigationItem() {
        const preselectedItem = config.navigationBarItems.find(n => n.type === urlParams.get("arrivalItem"));
        if (preselectedItem) {
            setActiveItem(preselectedItem.type)
        } else {
            setActiveItem(config.navigationBarItems[0].type)
        }
    }

    function selectLocationTypeFilters() {
        const availableLocationTypes = config.locationTypes.map(l => l.type);
        const preselectedFilters = decodeURIComponent(urlParams.get("locationFilters")).split(",").filter(l => availableLocationTypes.includes(l));
        const filterObject = {}
        preselectedFilters.forEach(f => filterObject[f] = true)
        setActiveLocationTypeFilters(filterObject);
    }

    function setCSSThemingVariables() {
        const root = document.documentElement;
        const primaryColor = config.theme.primaryColor || "#a99c94";
        const tabBarColor = config.theme.tabBarColor || primaryColor;

        function increaseLightnessByFixedAmount(primaryColor, number) {
            const hslValues = new Color(primaryColor).hsl().object();
            return new Color({...hslValues, l: Math.min(hslValues.l + number, 100)}).hex();
        }

        function decreaseLightnessByFixedAmount(primaryColor, number) {
            const hslValues = new Color(primaryColor).hsl().object();
            return new Color({...hslValues, l: Math.max(hslValues.l - number, 0)}).hex();
        }

        function increaseTransparencyByFixedAmount(primaryColor, versionDecrement) {
            const hslValues = new Color(primaryColor).hsl().object();
            return new Color({...hslValues, alpha: Math.max((hslValues.alpha || 1) - versionDecrement, 0)});
        }

        const lighterVersions = [11, 15, 28, 30, 36]
        const darkerVersions = [5, 10, 12, 15, 18, 20, 30]
        const transparencyVersions = [0.8, 0.84, 0.85, 0.93, 1];

        function defineLighterVersionOfPrimaryColor(versionIncrement) {
            root.style.setProperty(`--primary-color-lighter-${versionIncrement}`, increaseLightnessByFixedAmount(primaryColor, versionIncrement))
        }

        function defineDarkerVersionOfPrimaryColor(versionDecrement) {
            root.style.setProperty(`--primary-color-darker-${versionDecrement}`, decreaseLightnessByFixedAmount(primaryColor, versionDecrement))
        }

        function defineMoreTransparentPrimaryColor(versionDecrement) {
            root.style.setProperty(`--primary-color-more-transparent-${versionDecrement * 100}`, increaseTransparencyByFixedAmount(primaryColor, versionDecrement))
        }

        lighterVersions.forEach(defineLighterVersionOfPrimaryColor)
        darkerVersions.forEach(defineDarkerVersionOfPrimaryColor)
        transparencyVersions.forEach(defineMoreTransparentPrimaryColor)

        root.style.setProperty("--primary-color", primaryColor);
        root.style.setProperty("--tab-bar-color", tabBarColor);
    }

    function locationSelected(location) {
        return () => setSelectedLocation(location)
    }

    function onDesktopLocationSheetMouseEnter(location) {
        return () => {
            if (!onMobile.matches) {
                setHoveredLocation(location)
            }
        }
    }

    function onDesktopLocationSheetMouseLeave() {
        return () => {
            if (!onMobile.matches) {
                setHoveredLocation(null)
            }
        }
    }

    function enterFullscreenMode() {
        if (!fullscreen) {
            setFullscreen(true)
            window.parent.postMessage({event: 'enter-fullscreen', index: urlParams.get("index") || 0}, '*')
        }
    }

    function leaveFullScreenMode() {
        if (fullscreen) {
            setFullscreen(false)
            window.parent.postMessage({event: 'leave-fullscreen', index: urlParams.get("index") || 0}, '*')
        }
    }

    function toggleLocationTypeFilter(locationType) {
        if (activeLocationTypeFilters[locationType]) {
            setActiveLocationTypeFilters({
                ...activeLocationTypeFilters,
                [locationType]: false,
            })
        } else {
            setActiveLocationTypeFilters({
                ...activeLocationTypeFilters,
                [locationType]: true,
            })
        }
    }

    function FullscreenButton() {
        const action = fullscreen ? leaveFullScreenMode : enterFullscreenMode
        const iconClasses = fullscreen
            ? 'map__button-icon map__button-icon--fullscreen-exit'
            : 'map__button-icon map__button-icon--fullscreen'
        return (
            <button className='map__button' onClick={action}>
                <div className={iconClasses}></div>
            </button>
        )
    }

    function displayLocationSheet() {
        return (
            (selectedLocation && !onMobile.matches) ||
            (selectedLocation && displayLocationInfoOnMobile)
        )
    }

    function closeLocationDetails() {
        if (onMobile.matches) {
            setDisplayLocationInfoOnMobile(false)
        } else {
            setSelectedLocation(null)
        }
    }

    function noActiveFilter() {
        return !_.values(activeLocationTypeFilters).some(_.identity)
    }

    function updateLocations() {
        if (activeTab === 'surrounding') {
            if (noActiveFilter()) {
                setLocations(config.surrounding.filter(location => location.default))
            } else {
                setLocations(
                    config.surrounding.filter(
                        location => _.some(location.type, type => activeLocationTypeFilters[type])
                    ),
                )
            }
        }

        if (activeTab === 'arrival') {
            setLocations(
                config.arrival.filter(location => location.group === activeItem),
            )
        }

        return []
    }

    return (
        <LanguageContext.Provider value={getUserLanguage()}>
            <div
                className={classNames('hotelmapy', {
                    'hotelmapy--locations-hidden': activeTab !== 'surrounding',
                })}
            >
                {displayLocationSheet() && (
                    <LocationDetails
                        location={selectedLocation}
                        closeLocationDetails={() => closeLocationDetails()}
                        activeLocationTypeFilters={activeLocationTypeFilters}
                        locationTypes={[...config.locationTypes, ...config.transportationTypes]}
                        hotelLocation={config.hotel.location}
                    />
                )}
                {config && (
                    <Tabs
                        tabs={TABS}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                )}
                {config && activeTab === 'surrounding' && (
                    <React.Fragment>
                        <FilterBar
                            locationTypes={config.locationTypes}
                            activeLocationTypeFilters={activeLocationTypeFilters}
                            toggleLocationTypeFilter={toggleLocationTypeFilter}
                            fullscreen={fullscreen}
                        />
                        <Locations
                            className='location-info'
                            locations={locations}
                            locationSelected={locationSelected}
                            selectedLocation={selectedLocation}
                            onDesktopLocationSheetMouseEnter={onDesktopLocationSheetMouseEnter}
                            onDesktopLocationSheetMouseLeave={onDesktopLocationSheetMouseLeave}
                            setDisplayLocationInfoOnMobile={setDisplayLocationInfoOnMobile}
                            activeLocationTypeFilters={activeLocationTypeFilters}
                            config={config}
                        />
                    </React.Fragment>
                )}
                {config && activeTab === 'arrival' && (
                    <React.Fragment>
                        <ArrivalNavigationBar
                            items={config.navigationBarItems}
                            setActiveItem={setActiveItem}
                            activeItem={activeItem}
                        />
                        <Locations
                            className='location-info'
                            locations={locations}
                            locationSelected={locationSelected}
                            selectedLocation={selectedLocation}
                            onDesktopLocationSheetMouseEnter={onDesktopLocationSheetMouseEnter}
                            onDesktopLocationSheetMouseLeave={onDesktopLocationSheetMouseLeave}
                            setDisplayLocationInfoOnMobile={setDisplayLocationInfoOnMobile}
                            activeLocationTypeFilters={activeLocationTypeFilters}
                            config={config}
                        />
                    </React.Fragment>
                )}

                <div className='map'>
                    <FullscreenButton/>
                    {config && (
                        <GoogleMap
                            locationSelected={locationSelected}
                            selectedLocation={selectedLocation}
                            config={config}
                            activeTab={activeTab}
                            hoveredLocation={hoveredLocation}
                            locations={locations}
                            showTransitLines={
                                activeTab === 'arrival' && activeItem === 'public'
                            }
                            fullscreen={fullscreen}
                            onMobile={onMobile.matches}
                            onLargeScreen={onLargeScreen.matches}
                            activeLocationTypeFilters={activeLocationTypeFilters}
                            activeRunningRoute={selectedLocation?.route}
                            runningRoutes={activeLocationTypeFilters.runningRoute ? locations.filter(l => l.type.includes("runningRoute")) : []}
                        />
                    )}
                </div>
            </div>
        </LanguageContext.Provider>
    )
}

export default Hotelmapy

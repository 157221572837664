import React, {Fragment, useContext, useState} from 'react'
import classNames from 'classnames'
import ActionBar from './action_bar'
import {getSingularTypeName} from './utils'
import TranslateValue, {useTranslateValue} from './translated_value'
import {LanguageContext} from './hotelmapy'
import {FormattedMessage, useIntl} from 'react-intl'
import _ from 'lodash'

const DAY_NAMES = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
]

function LocationDetails({location, closeLocationDetails, locationTypes, activeLocationTypeFilters, hotelLocation}) {
    const [activePanel, setActivePanel] = useState('description')
    const locationDetailsHeadings = ['Beschreibung', 'Kontakt']
    const language = useContext(LanguageContext)
    const {formatNumber} = useIntl()
    const translatedValue = useTranslateValue();

    const highDistance = location.distance >= 1000


    function ReachabilityInformation() {
        function formatDistance(distance) {
            return `${formatNumber(highDistance ? distance / 1000 : distance)} ${highDistance ? 'km' : 'm'}`
        }

        return (
            <React.Fragment>
                {location.distance && (
                    <div className='location-details__measured-distance'>
                        {formatDistance(location.distance)}
                    </div>
                )}

                {location.walkingMinutes && (
                    <div className='location-details__walking-distance'>
                        {' '}
                        {location.walkingMinutes} Min.
                    </div>
                )}

                {location.drivingMinutes &&
                (location.drivingMinutes > 5 || location.group === 'flight') ? (
                    <div className='location-details__driving-minutes'>
                        {' '}
                        {location.drivingMinutes} Min.
                    </div>
                ) : null}
                {location.runningDistance && (
                    <div className='location-details__running-distance'>
                        {' '}
                        {formatDistance(location.runningDistance)}
                    </div>
                )}

                {location.runningMinutes && (
                    <div className='location-details__running-minutes'>
                        {' '}
                        {location.runningMinutes} Min.
                    </div>
                )}      </React.Fragment>
        )
    }

    function ContactInformation() {
        return (
            <React.Fragment>
                {' '}
                {location.address && location.phone ? (
                    <div className='location-details__description-section '>
                        <div className='location-details__adress'>{location.address}</div>
                        <a className='location-details__phone' href='tel:{location.phone}' target="_parent">
                            {location.phone}
                        </a>
                    </div>
                ) : null}
            </React.Fragment>
        )
    }

    function ConnectionTrafficDescription() {
        function formatedTraffics(arrival) {
            return (
                arrival && (
                    <div
                        className={classNames(
                            'location-details__arrival location-details__arrival-db',
                            {
                                'location-details__arrival location-details__arrival-sbahn':
                                    arrival === location.sbahn,
                                'location-details__arrival location-details__arrival-ubahn':
                                    arrival === location.ubahn,
                                'location-details__arrival location-details__arrival-bus':
                                    arrival === location.bus,
                            },
                        )}
                    >
                        {arrival.map(stop => (
                            <span key={stop} className='location-details__arrival-element'>
                {stop}
              </span>
                        ))}
                    </div>
                )
            )
        }

        return (
            <React.Fragment>
                <div className='location-details__description-section'>
                    <div className='location-details__description-text'>
                        <TranslateValue values={location.description}/>
                    </div>
                    {location.group === 'public' && (
                        <React.Fragment>
                            {formatedTraffics(location.db)}

                            {formatedTraffics(location.sbahn)}

                            {formatedTraffics(location.ubahn)}

                            {formatedTraffics(location.bus)}
                        </React.Fragment>
                    )}{' '}
                </div>
            </React.Fragment>
        )
    }

    function TimeTables() {
        const seasonalOpeningHoursData = seasonalOpeningHours(translatedValue)
        const departures = location.departures
        const seasonalDepartures = location.seasonalDepartures

        if (location.openingHours || seasonalOpeningHoursData) {
            return (<OpeningHours openingHours={location.openingHours}
                                  seasonalOpeningHours={seasonalOpeningHoursData}/>)
        }
        if (departures || seasonalDepartures) {
            return (<Departures departures={location.departures}
                                seasonalDepartures={location.seasonalDepartures}
                                remarks={location.departures?.remarks}/>)
        }

        return <EmptyOpeningHours/>;

    }

    function Panel() {

        return activePanel === 'description' ? (
            <div className='location-details__tab-panel'>
                <div className='location-details__description'>
                    <div className='location-details__description-text'>
                        <TranslateValue values={location.description}/>
                    </div>
                </div>
            </div>
        ) : (
            <div className='location-details__tab-panel'>
                <ActionBar
                    locationPhone={location.phone}
                    locationURL={location.url}
                    locationLat={location.lat}
                    locationLong={location.lng}
                    hotelLocation={hotelLocation}
                    route={location.routeSteps}
                    locationTypes={location.type}/>
                <div className='location-details__contact-information'>
                    {ContactInformation()}
                </div>

            </div>
        )
    }

    function tabButtonClassNames(tabname) {
        return classNames('location-details__tab-button', {
            'location-details__tab-button--active': activePanel === tabname,
        })
    }

    function openingHours(translateValue) {
        return location.openingHours ? translateValue(location.openingHours.hours).split(';') : []
    }


    function seasonalOpeningHours(translatedValue) {
        return location.seasonalOpeningHours && location.seasonalOpeningHours.map(season => ({
            ...season,
            openingHours: translatedValue(season.hours).split(';'),
        }))
    }

    return (
        <div className='location-details'>
            <div className='location-details__description'>
                <div className='location-details__header'>
                    <div
                        className='location-details__close-button-container'
                        onClick={closeLocationDetails}
                    >
                        <button className='location-details__close-button'>close</button>
                    </div>
                    <div className='location-details__header-information'>
                        <div
                            className='location-details__type'>{getSingularTypeName(locationTypes, activeLocationTypeFilters, location.type, language)}</div>
                        <div className='location-details__name'><TranslateValue values={location.name}/></div>
                        {' '}
                        <div className='location-details__reachability'>
                            {ReachabilityInformation()}
                        </div>
                    </div>
                </div>
                {!location.group || location.showContactDetails ? (
                    <div>
                        <div className='location-details__tabs'>
                            <div className='location-details__tab-buttons'>
                                <div
                                    className={tabButtonClassNames('description')}
                                    onClick={() => setActivePanel('description')}
                                >
                                    {locationDetailsHeadings[0]}
                                </div>
                                <div
                                    className={tabButtonClassNames('opening-hours')}
                                    onClick={() => setActivePanel('opening-hours')}
                                >
                                    {locationDetailsHeadings[1]}
                                </div>
                            </div>
                            <Panel/>
                        </div>

                        <div className='location-details__description-without-tabs'>
                            <div className='location-details__description-text location-details__description-section '>
                                <TranslateValue values={location.description}/>
                            </div>
                            {' '}
                            <ActionBar
                                locationPhone={location.phone}
                                locationURL={location.url}
                                locationLat={location.lat}
                                locationLong={location.lng}
                                hotelLocation={hotelLocation}
                                route={location.routeSteps}
                                locationTypes={location.type}
                            />
                            {ContactInformation()}
                            {!location.type.includes('runningRoute') &&
                            <div className='location-details__description-section'>
                                <TimeTables/>
                            </div>}
                        </div>
                    </div>
                ) : (
                    <ConnectionTrafficDescription/>
                )}
            </div>
        </div>
    )
}

function OpeningHoursWithoutSeason({openingHours, remarks}) {
    return <div><OpeningHoursList seasonalData={openingHours}/><OpeningHoursRemarks
        remarks={remarks}/></div>
}

function DeparturesWithoutSeason({departures}) {
    return <div><DeparturesList seasonalData={departures}/><OpeningHoursRemarks
        remarks={departures.remarks}/></div>
}

function Departures({departures, seasonalDepartures}) {
    const departuresPresent = departures || seasonalDepartures;

    return <React.Fragment>
        <div className='location-details__opening-hours-title'>
            <FormattedMessage id="DEPARTURES"/>*
        </div>
        {departuresPresent ? (<React.Fragment>
                {
                    seasonalDepartures ?
                        <SeasonalPanels seasonalData={seasonalDepartures} panelComponent={DeparturesList}/> :
                        <DeparturesWithoutSeason departures={departures}/>}
                <div className="location-details__legal-note-opening-hours">
                    <FormattedMessage id="WITHOUT_GUARANTEE"/></div>
            </React.Fragment>)
            : <EmptyOpeningHours/>}
    </React.Fragment>
}

function OpeningHours({openingHours, seasonalOpeningHours}) {
    const openingHoursPresent = openingHours?.hours?.de || seasonalOpeningHours?.hours?.de

    return <React.Fragment>
        <div className='location-details__opening-hours-title'>
            <FormattedMessage id="OPENING_HOURS"/>*
        </div>
        {openingHoursPresent ? (<React.Fragment>
                {
                    seasonalOpeningHours ?
                        <SeasonalPanels seasonalData={seasonalOpeningHours} panelComponent={OpeningHoursList}/> :
                        <OpeningHoursWithoutSeason openingHours={openingHours.hours} remarks={openingHours.remarks}/>}
                <div className="location-details__legal-note-opening-hours">
                    <FormattedMessage id="WITHOUT_GUARANTEE"/></div>
            </React.Fragment>)
            : <EmptyOpeningHours/>}
    </React.Fragment>
}

function getActiveSeasonIndex(sortedSeasonalOpeningHours, currentDayMonth) {
    return sortedSeasonalOpeningHours.findIndex((s, i) => s.startMonthDay <= currentDayMonth && s.endMonthDay >= currentDayMonth || (sortedSeasonalOpeningHours.length - 1) === i)
}

function getCurrentDayMonth() {
    return (new Date()).toISOString().substr(5, 5)
}

function getSortedSeasonalOpeningHours(seasonalOpeningHours) {
    return _.sortBy(seasonalOpeningHours, s => s.startMonthDay)
}

function SeasonalPanels({seasonalData, panelComponent}) {
    const sortedSeasonalOpeningHours = getSortedSeasonalOpeningHours(seasonalData)
    const currentDayMonth = getCurrentDayMonth()
    const activeSeasonIndex = getActiveSeasonIndex(sortedSeasonalOpeningHours, currentDayMonth)
    const sortedSeasonalOpeningHoursBeginningWithCurrent = [...sortedSeasonalOpeningHours].splice(activeSeasonIndex)
    const sortedSeasonalOpeningHoursEndingBeforeCurrent = [...sortedSeasonalOpeningHours].splice(0, activeSeasonIndex)
    const sortedSeasonalOpeningHoursWithCorrectedOrder = [...sortedSeasonalOpeningHoursBeginningWithCurrent, ...sortedSeasonalOpeningHoursEndingBeforeCurrent]
    const onlyObeSeasonPresent = seasonalData.length === 1;

    return sortedSeasonalOpeningHoursWithCorrectedOrder.map((s, i) => <SeasonalPanel seasonalData={s}
                                                                                     initiallyActive={i === 0}
                                                                                     key={s.startMonthDay}
                                                                                     panelComponent={panelComponent} notClosable={onlyObeSeasonPresent}/>,
    )
}

function OpeningHoursRemarks({remarks}) {
    const translateValue = useTranslateValue();
    if (!remarks) {
        return null
    }
    return (<div className="location-details__opening-hours-remark" dangerouslySetInnerHTML={{__html: translateValue(remarks)}}/>)
}

function SeasonalPanel({seasonalData, initiallyActive, panelComponent, notClosable}) {
    const [active, setActive] = useState(initiallyActive)

    const PanelComponent = panelComponent;

    return <div
        className={classNames('location-details__opening-hours-season', {'location-details__opening-hours-season--active': active, 'location-details__opening-hours-season--not-closable': notClosable})}>
        <div className="location-details__opening-hours-season-header" onClick={notClosable ? null : () => setActive(!active)}>
            <div className="location-details__opening-hours-season-header-title"><TranslateValue
                values={seasonalData.title}/></div>
            <div className="location-details__opening-hours-season-header-icon">
                {active ? 'expand_more' : 'expand_less'}</div>
        </div>
        <div className="location-details__opening-hours-container">
            <PanelComponent seasonalData={seasonalData.hours}/>
            <OpeningHoursRemarks remarks={seasonalData.remarks}/>
        </div>
    </div>
}


function EmptyOpeningHours() {
    return <div className='location-details__description-opening-hours-none'>
        <FormattedMessage id="NO_OPENING_HOURS"/>
    </div>
}

function OpeningHoursList({seasonalData}) {
    const translateValue = useTranslateValue();
    const dayList = translateValue(seasonalData).split(";")

    return <dl className='location-details__description-opening-hours'>
        {
            DAY_NAMES.map((dayName, i) => (
                <React.Fragment key={dayName}>
                    <dt><FormattedMessage id={dayName}/></dt>
                    <dd dangerouslySetInnerHTML={{__html: dayList[i]}}/>
                </React.Fragment>
            ))}
    </dl>
}

function DeparturesList({seasonalData}) {
    return <dl className='location-details__description-opening-hours'>
        {
            seasonalData.map((departureEntry, i) => (
                <React.Fragment key={departureEntry[0]['de']}>
                    <dt><TranslateValue values={departureEntry[0]}/></dt>
                    <dd>{departureEntry[1].join(", ")}</dd>
                </React.Fragment>
            ))}
    </dl>
}

export default LocationDetails

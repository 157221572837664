import * as React from "react";

function SvgShoppingDefault(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#shopping-default_svg__clip0)">
        <g filter="url(#shopping-default_svg__filter0_dd)">
          <path d="M13 22a9 9 0 100-18 9 9 0 000 18z" fill={props.markerColor} />
        </g>
        <path
          d="M11.07 12.246a2.679 2.679 0 001.93.787c.745 0 1.38-.262 1.904-.787.542-.525.813-1.16.813-1.904h-1.092c0 .44-.16.82-.482 1.142a1.562 1.562 0 01-1.143.483c-.44 0-.821-.161-1.143-.483a1.562 1.562 0 01-.482-1.142h-1.092c0 .745.263 1.38.787 1.904zm3.073-4.139A1.562 1.562 0 0013 7.625c-.44 0-.821.16-1.143.482a1.562 1.562 0 00-.482 1.143h3.25c0-.44-.16-.821-.482-1.143zm2.64 1.143c.288 0 .542.11.762.33.22.22.33.474.33.762v6.5c0 .288-.11.542-.33.762a1.09 1.09 0 01-.762.304H9.217a1.09 1.09 0 01-.762-.305c-.22-.22-.33-.473-.33-.761v-6.5c0-.288.11-.542.33-.762.22-.22.474-.33.762-.33h1.066c0-.745.263-1.38.787-1.904.542-.542 1.185-.813 1.93-.813.745 0 1.38.271 1.904.813.542.524.813 1.16.813 1.904h1.066z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="shopping-default_svg__clip0">
          <path fill={props.markerColor} d="M0 0h26v26H0z" />
        </clipPath>
        <filter
          id="shopping-default_svg__filter0_dd"
          x={0}
          y={2}
          width={26}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgShoppingDefault;

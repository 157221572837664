import * as React from "react";

function SvgParkingActive(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#parking-active_svg__clip0)">
        <path
          d="M11.523 23.59C13.708 22.122 22 16.21 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.21 8.292 11.122 10.477 12.59a.929.929 0 001.046 0z"
          fill={props.markerColor}
        />
        <path
          d="M11.71 10.426c.31 0 .575-.119.794-.356.237-.237.355-.51.355-.82 0-.31-.118-.583-.355-.82a1.04 1.04 0 00-.793-.356H9.824v2.352h1.887zm-.136-4.676c.966 0 1.787.346 2.461 1.04a3.306 3.306 0 011.04 2.46c0 .966-.347 1.796-1.04 2.488-.674.675-1.495 1.012-2.46 1.012h-1.75v3.5H7.5V5.75h4.074z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="parking-active_svg__clip0">
          <path fill={props.iconColor} d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgParkingActive;

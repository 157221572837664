import * as React from "react";

function SvgParkHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#park-hover_svg__clip0)">
        <path
          d="M21.75 12c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 22.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 14.43 4.25 13.17 4.25 12a8.75 8.75 0 0117.5 0z"
          fill={props.markerColor}
          stroke={props.outlineColor}
          strokeWidth={0.5}
        />
        <path
          d="M9.521 10.738a.791.791 0 01-.584.229.791.791 0 01-.583-.229.848.848 0 01-.229-.584.76.76 0 01.229-.558.75.75 0 01.584-.254.75.75 0 01.583.254.76.76 0 01.229.558c0 .22-.076.415-.229.584zm8.989-.761c0 .964-.322 1.802-.965 2.513a3.75 3.75 0 01-2.387 1.244v2.108h1.625v1.066H8.125v-2.691h-.533v-2.184a.517.517 0 01.533-.533H9.75a.517.517 0 01.533.533v2.184H9.75v1.625h4.342v-2.133a3.782 3.782 0 01-2.285-1.27 3.735 3.735 0 01-.889-2.462c0-1.05.372-1.947 1.117-2.692.745-.745 1.642-1.117 2.692-1.117 1.049 0 1.938.372 2.666 1.117.744.745 1.117 1.642 1.117 2.692z"
          fill={props.iconColor}
        />
      </g>
      <defs>
        <clipPath id="park-hover_svg__clip0">
          <path fill={props.iconColor} d="M0 0h26v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgParkHover;

import * as React from "react";

function SvgFastfoodHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.75 13c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M15.755 17h.754a.751.751 0 00.741-.664L18 8.842h-2.273V7h-.895v1.842h-2.26l.137 1.064c.777.214 1.505.6 1.941 1.028.655.645 1.104 1.314 1.104 2.405V17zM8 16.545v-.45h6.832v.45c0 .25-.205.455-.46.455H8.46A.457.457 0 018 16.545zm6.832-3.183c0-3.638-6.832-3.638-6.832 0h6.832zm-6.823.914h6.818v.91H8.01v-.91z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgFastfoodHover;

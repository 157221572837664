import * as React from "react";

function SvgEntertainmentHover(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.697l-.242-.17a38.517 38.517 0 01-4.086-3.345c-1.118-1.057-2.228-2.262-3.058-3.505C4.782 15.43 4.25 14.17 4.25 13a8.75 8.75 0 0117.5 0c0 1.171-.532 2.43-1.364 3.677-.83 1.243-1.94 2.448-3.058 3.505A38.519 38.519 0 0113 23.697z"
        fill={props.markerColor}
        stroke={props.outlineColor}
        strokeWidth={0.5}
      />
      <path
        d="M14.93 14.59l-.584-2.21 1.777-1.472-2.285-.127L13 8.648l-.838 2.133-2.31.127 1.802 1.473-.584 2.209L13 13.346l1.93 1.244zm2.716-3.352a1.09 1.09 0 00-.304.762c0 .288.101.542.305.762.22.22.473.33.761.33v2.158c0 .288-.11.542-.33.762a.966.966 0 01-.736.33H8.658c-.288 0-.541-.11-.762-.33a1.09 1.09 0 01-.304-.762v-2.158c.304 0 .558-.102.762-.305A1.12 1.12 0 008.658 12c0-.288-.11-.542-.33-.762a.966.966 0 00-.736-.33V8.75c0-.288.101-.542.304-.762.22-.22.474-.33.762-.33h8.684c.288 0 .533.11.736.33.22.22.33.474.33.762v2.158c-.288 0-.542.11-.762.33z"
        fill={props.iconColor}
      />
    </svg>
  );
}

export default SvgEntertainmentHover;
